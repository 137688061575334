import React from "react";
import { connect } from "react-redux";
import { message } from "antd";

import CPCoursesPlanModal from "../components/CPCoursesPlanModal/CPCoursesPlanModal";
import { closeCoursesPlanModal } from "../redux/ControlPanel/ControlPanel.actions";
import { createCourse } from "../services/courses.service";
import {
  changeCourseForm,
  getCourses,
  saveCourse,
} from "../redux/Courses/Courses.action";

class AssociateCourseForm extends React.Component {
  createCourse = () => {
    const {
      handleCreateCourse,
      courseModalData: { form },
      planDetails,
    } = this.props;

    if (!form.name || !form.description) {
      return message.warning("Preencha todos os campos obrigatórios");
    }

    return handleCreateCourse({
      ...form,
      coursePackageId: planDetails.id,
    });
  };

  saveCourse = () => {
    const {
      handleSaveCourse,
      courseModalData: { form },
      planDetails,
    } = this.props;

    if (!form.name || !form.description) {
      return message.warning("Preencha todos os campos obrigatórios");
    }

    return handleSaveCourse({
      ...form,
      coursePackageId: planDetails.id,
    });
  };

  onChangeForm = (value) => {
    const { handleChangeCourseForm, courseModalData } = this.props;
    handleChangeCourseForm({
      ...courseModalData.form,
      ...value,
    });
  };

  render() {
    const { handleCloseModal, courseModalData, coursesList, planDetails } =
      this.props;
    return (
      <CPCoursesPlanModal
        {...courseModalData}
        closeModal={handleCloseModal}
        changeForm={this.onChangeForm}
        createCourse={this.createCourse}
        saveCourse={this.saveCourse}
        coursesList={coursesList}
        planDetails={planDetails}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  coursesPlanModalData: state.courses.coursesPlanModalData,
  planDetails: state.plans.planDetails,
  coursesList: state.courses.coursesList,
});

const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(closeCoursesPlanModal()),
  handleCreateCourse: (form) => dispatch(createCourse(form)),
  handleGetCourses: (coursePackageIds, includeTest, listAll) =>
    dispatch(getCourses(coursePackageIds, includeTest, listAll)),
  handleSaveCourse: (form) => dispatch(saveCourse(form)),
  handleChangeCourseForm: (form) => dispatch(changeCourseForm(form)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociateCourseForm);
