import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:3333/'
})

api.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    ...headersWithToken()
  }
  return config
})

export function post(headers, params, url, cancelToken) {
  return api.post(url, params, {
    headers,
    cancelToken
  })
}

export function get(headers, params, url, cancelToken, options = {}) {
  return api.get(url, {
    headers,
    params,
    cancelToken,
    ...options
  })
}

export function remove(headers, params, url) {
  return api.delete(url, {
    params,
    headers
  })
}

export function put(headers, params, url) {
  return api.put(url, params, {
    headers
  })
}

export function patch(headers, url) {
  return api.patch(url, undefined, {
    headers
  })
}

export function headersWithToken() {
  const session = JSON.parse(localStorage.getItem('wjSchoolSession') || '{}')

  if (!session.userData) return {}

  return {
    Authorization: `Bearer ${session.userData.token}`,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
}

export const httpHelper = {
  get,
  post,
  remove,
  put,
  patch
}
