import React, { useState } from "react";
import { Button, Input } from "antd";

import styles from "./CommentArea.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { addComment } from "../../redux/Course/Course.action";

const { TextArea } = Input;

export default function CommentArea({ commentFatherId, classId }) {
  const dispatch = useDispatch();
  const [sketch, setSketch] = useState("");
  const packageId = useSelector((state) => state.course.data.course_package_id);
  const courseId = useSelector((state) => state.course.data.id);
  const userName = useSelector((state) => state.session.userData.user.name);

  const onChangeComment = (text) => {
    setSketch(text);
  };

  const handleOnSend = (commentFatherId) => {
    dispatch(
      addComment(
        classId,
        sketch,
        commentFatherId,
        packageId,
        courseId,
        userName
      )
    );
    setSketch("");
  };

  return (
    <div className={styles.CommentArea}>
      <TextArea
        rows={1}
        placeholder="Escreva seu comentário"
        autosize="true"
        onChange={(el) => onChangeComment(el.target.value)}
        value={sketch}
      />
      <Button type="primary" onClick={() => handleOnSend(commentFatherId)}>
        Enviar
      </Button>
    </div>
  );
}
