import React from "react";
import { useSelector } from "react-redux";
import styles from "./PurchasePlanResume.module.scss";
import logoPagSeguro from "../../images/logoPagSeguro.png";

export function PurchasePlanResume({}) {
  const plan = useSelector((state) => state.purchasePlan.plan);
  return (
    <div className={styles.PurchasePlanResume}>
      <strong>Resumo</strong>
      <div className="plan" key={plan.id}>
        <span className="planName">{plan.name}</span>
        <div className="planFeatures">
          {plan.tags.map((tag, index) => (
            <div className="tag" key={index}>
              <span className="material-symbols-outlined">check</span>
              <span>{tag}</span>
            </div>
          ))}
        </div>
        <span className="planPrice">
          {`Total: R$ ${plan.fullPrice.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          })}`}
        </span>
      </div>
      <div className="footer">
        <span>Pagamento processado pelo</span>
        <img src={logoPagSeguro} alt="PagSeguro" />
      </div>
    </div>
  );
}
