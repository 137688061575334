import React, { useEffect, useState } from "react";
import { Radio, Button } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import styles from "./ClassTest.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  finalizeTest,
  setRepeatTest,
  validateTest,
} from "../../redux/Course/Course.action";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

export default function ClassTest({ classData: { questions } }) {
  const dispatch = useDispatch();
  const [checkedItems, setCheckedItems] = useState([]);
  const [teste, setTeste] = useState(<></>);
  const packageId = useSelector((state) => state.course.data.course_package_id);
  const answers = useSelector((state) => state.course.answers);
  const repeatTest = useSelector((state) => state.course.repeatTest);
  const selectedClassId = useSelector(
    (state) => state.course.selectedClass?.classId
  );

  useEffect(() => {
    dispatch(validateTest(selectedClassId));
    setCheckedItems(
      questions.map((item) => ({
        question_id: item.id,
        current_answer: null,
      }))
    );
  }, [selectedClassId]);

  useEffect(() => {
    if (answers?.length === 0 || repeatTest) {
      setTeste(generateTest());

      return;
    }
    setTeste(generateTemplate());
  }, [answers, repeatTest, checkedItems, selectedClassId]);

  useEffect(() => {
    if (repeatTest && checkedItems.length > 0) {
      const checkedItemsWithId = checkedItems.map((checkedItem) => {
        const aux = {
          ...checkedItem,
        };
        const alreadyAnswer = answers.find(
          (answer) => checkedItem.question_id == answer.id
        );
        if (!!alreadyAnswer) {
          aux.id = alreadyAnswer?.user_answers[0]?.id;
        }
        return aux;
      });
      setCheckedItems(checkedItemsWithId);
    }
  }, [repeatTest]);

  const onChangeQuestion = (question_id, value) => {
    setCheckedItems(
      checkedItems.map((item) => {
        if (item.question_id !== question_id) return item;
        return {
          ...item,
          current_answer: value,
        };
      })
    );
  };

  const generateTest = () => (
    <div className={styles.ClassTest}>
      <div className="testTitle">Avaliação</div>
      <p className="testDescription">
        Leia atentamente as questões e assinale uma alternativa.
      </p>
      <div className="questions">
        {questions.map((item, index) => (
          <div className="item" key={index + Date.now()}>
            <div className="number">{index + 1}</div>
            <div className="content">
              <b className="title">{item.title}</b>
              <div className="options">
                <Radio.Group
                  onChange={(el) => onChangeQuestion(item.id, el.target.value)}
                  value={
                    (
                      checkedItems.find((el) => el.question_id === item.id) ||
                      {}
                    ).current_answer
                  }
                >
                  <Radio style={radioStyle} value="option_1" key="option_1">
                    {item.option_1}
                  </Radio>
                  <Radio style={radioStyle} value="option_2" key="option_2">
                    {item.option_2}
                  </Radio>
                  <Radio style={radioStyle} value="option_3" key="option_3">
                    {item.option_3}
                  </Radio>
                  <Radio style={radioStyle} value="option_4" key="option_4">
                    {item.option_4}
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
        ))}
      </div>
      {questions.length ===
        checkedItems.filter((el) => el.current_answer).length && (
        <Button
          type="primary"
          // className="finishBtn"
          size="large"
          onClick={() => {
            dispatch(
              finalizeTest(selectedClassId, packageId, checkedItems, repeatTest)
            );
          }}
        >
          Finalizar avaliação
        </Button>
      )}
    </div>
  );

  const generateTemplate = () => {
    const corrects = answers?.filter((item) => item.user_answers[0].is_correct);

    return (
      <div className={styles.Template}>
        <div className="resultPhrase">
          {`Parabéns, você acertou ${corrects.length} de ${answers?.length}!`}
        </div>
        <ul className="questions">
          {answers?.map((question, index) => (
            <li key={question.id + index + Date.now()}>
              <div className="number">
                {index + 1}
                <span className="result">
                  {question.user_answers[0].is_correct ? (
                    <CheckCircleFilled className="icon correct" />
                  ) : (
                    <CloseCircleFilled className="icon incorrect" />
                  )}
                </span>
              </div>
              <div className="content">
                <span className="questionTitle">{question.title}</span>
                {question.user_answers[0].is_correct ? (
                  <span className="result correct">Correto</span>
                ) : (
                  <span className="result incorrect">Incorreto</span>
                )}
              </div>
            </li>
          ))}
        </ul>
        <Button
          type="primary"
          className="btn"
          size="large"
          onClick={() => dispatch(setRepeatTest(true))}
        >
          Refazer
        </Button>
      </div>
    );
  };
  return teste;
}
