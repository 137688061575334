export const getSession = () =>
  JSON.parse(localStorage.getItem('wjSchoolSession') || '{}')

export const getCurrentUser = () => {
  const session = getSession()

  if (!session.sessionUser) {
    throw new Error('Session user not found on local storage.')
  }

  return session.sessionUser
}

export const setSession = (session) => {
  localStorage.setItem('wjSchoolSession', JSON.stringify(session))
  return session
}
