import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "./Header";
import HomeBanner from "../components/HomeBanner/HomeBanner";
import HomeAbout from "../components/HomeAbout/HomeAbout";
import HomeCourses from "../components/HomeCourses/HomeCourses";
import HomePlans from "../components/HomePlans/HomePlans";
import { getCourses } from "../redux/Courses/Courses.action";
import { getPlans } from "../redux/Plans/Plans.action";
import Footer from "./Footer";
import { useEffect } from "react";
import { message } from "antd";

export default function Home({ location }) {
  const dispatch = useDispatch();
  const planList = useSelector((state) => state.plans.list);
  useEffect(() => {
    dispatch(getCourses());
    dispatch(getPlans());
  }, []);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search.substring(1));
      const msg = params.get("mensagem");
      if (msg) {
        message.success(msg);
      }
    }
  }, [location.search]);

  return (
    <>
      <Header isHome />
      <HomeBanner />
      <HomeAbout />
      <HomeCourses />
      <HomePlans list={planList} />
      <Footer />
    </>
  );
}
