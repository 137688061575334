import React from "react";
import { Row, Col, Steps, Button, Spin } from "antd";

import styles from "./PurchasePlanForm.module.scss";
import PersonalDataForm from "./PersonalData/PersonalDataForm";
import PaymentInformationForm from "./PaymentInformation/PaymentInformationForm";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import PaymentThanks from "./PaymentThanks/PaymentThanks";

const { Step } = Steps;

const steps = [
  {
    title: "Dados pessoais",
    content: (props) => {
      return <PersonalDataForm {...props} />;
    },
  },
  {
    title: "Pagamento",
    content: (props) => <PaymentInformationForm {...props} />,
  },
  {
    title: "Obrigado!",
    content: (props) => <PaymentThanks />,
  },
];
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export function PurchasePlanForm({
  step,
  next,
  prev,
  form,
  onChangeForm,
  onSubmitForm,
}) {
  const loading = useSelector((state) => state.purchasePlan.loading);
  return (
    <div className={styles.PurchasePlanForm}>
      <Row>
        <Col span={24}>
          <Steps current={step} className="steps">
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            {steps[step].content({ form, onChangeForm })}
          </div>

          <div className="steps-action">
            {step > 0 && step != steps.length - 1 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Anterior
              </Button>
            )}
            {step < 1 && (
              <Button type="primary" onClick={() => next()}>
                Próximo
              </Button>
            )}
            {step === 1 && (
              <Button
                disabled={loading}
                type="primary"
                onClick={() => onSubmitForm(form)}
                style={{ width: "100px" }}
              >
                {loading ? <Spin indicator={antIcon} /> : "Finalizar"}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PurchasePlanForm;
