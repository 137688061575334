import React, { useEffect } from "react";
import moment from "moment";
import { Row, Button, Table } from "antd";
import styles from "./CPCoursesList.module.scss";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import history from "../../utils/history";
import {
  deleteCourse,
  getCourses,
  openCourseModal,
  openCourseModalToEdit,
} from "../../redux/Courses/Courses.action";
import CourseForm from "../../containers/CourseForm";
import Header from "../../containers/Header";
import AdmContainer from "../AdmContainer/AdmContainer";
import ControlPanelMenu from "../ControlPanelMenu/ControlPanelMenu";
import Footer from "../../containers/Footer";

export default function CPCoursesList({ location }) {
  const coursesList = useSelector((state) => state.courses.coursesList);
  const courseModalData = useSelector((state) => state.courses.courseModalData);
  const loading = useSelector((state) => state.courses.loading);
  const dispatch = useDispatch();
  const handleDeleteCourse = (courseId) => {
    confirm({
      title: "Tem certeza que deseja excluir este curso?",
      icon: <ExclamationCircleOutlined />,
      content: "Esta ação não poderá ser revertida",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => dispatch(deleteCourse(courseId)),
    });
  };
  const openCourseConfig = (id) => {
    history.push(`/control-panel/courses/${id}`);
  };

  const handleOpenCourseModalToEdit = (course) =>
    dispatch(openCourseModalToEdit(course));
  const handleOpenCourseModal = () => dispatch(openCourseModal());

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      render: (value) => moment(value).format("DD/MM/YYYY"),
    },
    {
      title: "Ações",
      dataIndex: "id",
      render: (value, row) => (
        <div className="actions">
          <button onClick={() => openCourseConfig(value)}>Ver</button>
          <button onClick={() => handleOpenCourseModalToEdit(row)}>
            Editar
          </button>
          <button onClick={() => handleDeleteCourse(value)}>Excluir</button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getCourses(undefined, true, true));
  }, []);

  return (
    <>
      <Header />
      <AdmContainer>
        <ControlPanelMenu menuActiveKey="courses" />
        <div className={styles.CPCoursesList}>
          <Row>
            <div className="pageTitle">
              <Breadcrumb title="Cursos" location={location} />
              <h1>Cursos</h1>
            </div>
          </Row>

          <Row>
            <Button type="primary" onClick={handleOpenCourseModal}>
              Adicionar Curso
            </Button>
          </Row>
          <Row>
            <Table
              columns={columns}
              dataSource={coursesList}
              className="coursesGrid"
              loading={loading}
            />
          </Row>
          {courseModalData.show && <CourseForm />}
        </div>
      </AdmContainer>
      <Footer />
    </>
  );
}
