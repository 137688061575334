import React from "react";
import { Carousel } from "antd";

import styles from "./HomeCourses.module.scss";
import SiteContainer from "../SiteContainer/SiteContainer";
import { useSelector } from "react-redux";
import CourseCard from "../CourseCard/CourseCard";

export default function HomeCourses() {
  const coursesList = useSelector((state) => state.courses.coursesList);

  const getCoursesPages = (list) => {
    const itemsPerPage = 6;
    const countPages = Math.ceil(list.length / itemsPerPage);
    const range = [...Array(countPages).keys()];
    return range.map((i) =>
      list.slice(itemsPerPage * i, (i + 1) * itemsPerPage)
    );
  };

  const onChange = (a, b, c) => {
    // console.log(a, b, c);
  };

  return (
    <div className={styles.HomeCourses}>
      <SiteContainer>
        <div className="title">
          Aprender um novo idioma na WJ School é:
        </div>
        <div className="text">
          <ul>
            <li>Ter acesso a uma metodologia de ensino inovadora e atualizada.</li>
            <li>Aprender no seu próprio ritmo.</li>
            <li>Acesso a um curso 100% online e a um excelente material didático.</li>
            <li>A praticidade de estudar no conforto da sua casa.</li>
          </ul>
        </div>
        <Carousel className="carousel" afterChange={onChange} infinite={false}>
          {getCoursesPages(coursesList)?.map((page, index) => (
            <div key={index}>
              <div className="page">
                {page.map((course) => (
                  <CourseCard isHome key={course.id} course={course} />
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </SiteContainer>
    </div>
  );
}
