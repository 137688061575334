import React from "react";
import styles from "./PaymentThanks.module.scss";
import { useSelector } from "react-redux";
import history from "../../../utils/history";
import { Button } from "antd";

function PaymentThanks() {
  const form = useSelector((state) => state.purchasePlan.form);
  const plan = useSelector((state) => state.purchasePlan.plan);
  return (
    <div className={styles.PaymentThanks}>
      <h1>
        <strong>Olá, {form.name}</strong>
      </h1>
      <p>
        Bem vindo ao Curso de {plan.name}! Está tudo pronto para você acessar
        seu curso. Ele faz parte de um dos pacotes oferecidos pelo nosso site.
        Aproveite ao máximo sua experiencia de aprendizado!
      </p>
      <span>Para acessar é muito simples.</span>
      <br />
      <span>Clique no botão abaixo!</span>
      <br />
      <Button
        className="acessButton"
        type="primary"
        onClick={() => history.push("/dashboard")}
      >
        Acesse agora
      </Button>
    </div>
  );
}

export default PaymentThanks;
