import React from "react";
import { Menu } from "antd";
import {
  UserOutlined,
  BookOutlined,
  BarChartOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

// import styles from "./ControlPanelMenu.module.scss";

export default function ControlPanelMenu({ menuActiveKey }) {
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const items = [
    getItem(
      <Link to="/control-panel/users">Usuários</Link>,
      "users",
      <UserOutlined />
    ),
    getItem(
      <Link to="/control-panel/courses">Cursos</Link>,
      "courses",
      <BookOutlined />
    ),
    getItem(
      <Link to="/control-panel/plan">Pacotes</Link>,
      "plans",
      <GiftOutlined />
    ),
  ];
  return (
    <Menu
      defaultSelectedKeys={[menuActiveKey]}
      mode="vertical"
      theme="light"
      items={items}
    />
  );
}
