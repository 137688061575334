import React, { useEffect } from "react";
import moment from "moment";
import { Row, Button, Table } from "antd";
import styles from "./CoursesTable.module.scss";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import history from "../../utils/history";
import {
  deleteCourse,
  getCourses,
  openCourseModal,
  openCourseModalToEdit,
} from "../../redux/Courses/Courses.action";
import CourseForm from "../../containers/CourseForm";

export default function CoursesTable({ location }) {
  const courseModalData = useSelector((state) => state.courses.courseModalData);
  const courses = useSelector((state) => state.courses.coursesList);
  const planDetails = useSelector((state) => state.plans.planDetails);
  const loading = useSelector((state) => state.courses.loading);
  const dispatch = useDispatch();
  const handleDeleteCourse = (courseId) => {
    confirm({
      title: "Tem certeza que deseja excluir este curso?",
      icon: <ExclamationCircleOutlined />,
      content: "Esta ação não poderá ser revertida",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => dispatch(deleteCourse(courseId)),
    });
  };
  const openCourseConfig = (id) => {
    history.push(`/control-panel/courses/${id}`);
  };

  const handleOpenCourseModalToEdit = (course) =>
    dispatch(openCourseModalToEdit(course));
  const handleOpenCourseModal = () => dispatch(openCourseModal());

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      render: (value) => moment(value).format("DD/MM/YYYY"),
    },
    {
      title: "Ações",
      dataIndex: "id",
      render: (value, row) => (
        <div className="actions">
          <button onClick={() => openCourseConfig(value)}>Ver</button>
          {!planDetails && (
            <button onClick={() => handleOpenCourseModalToEdit(row)}>
              Editar
            </button>
          )}
          <button onClick={() => handleDeleteCourse(value)}>Excluir</button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getCourses(undefined, true, true));
  }, []);

  return (
    <div className={styles.CPCoursesList}>
      <Row>
        <div className="pageTitle">
          <Breadcrumb title="Cursos" location={location} />
          <h1>{planDetails?.name}</h1>
        </div>
      </Row>

      {/* <Row>
        <Button type="primary" onClick={handleOpenCourseModal}>
          Associar Curso
        </Button>
      </Row> */}
      <Row>
        <Table
          columns={columns}
          dataSource={planDetails?.courses}
          className="coursesGrid"
          loading={loading}
        />
      </Row>
      {courseModalData.show && <CourseForm />}
    </div>
  );
}
