import { message } from 'antd'

import * as service from '../../services/courses.service'

export const COURSES__START_LOADING = 'COURSES__START_LOADING'
export const COURSES__STOP_LOADING = 'COURSES__STOP_LOADING'
export const COURSES_ADD_COURSE_TO_LIST = 'COURSES_ADD_COURSE_TO_LIST'
export const COURSES_REMOVE_COURSE_FROM_LIST = 'COURSES_REMOVE_COURSE_FROM_LIST'
export const COURSES_OPEN_COURSE_MODAL = 'COURSES_OPEN_COURSE_MODAL'
export const COURSES_CLOSE_COURSE_MODAL = 'COURSES_CLOSE_COURSE_MODAL'
export const COURSES_START_COURSE_MODAL_LOADING = 'COURSES_START_COURSE_MODAL_LOADING'
export const COURSES_STOP_COURSE_MODAL_LOADING = 'COURSES_STOP_COURSE_MODAL_LOADING'
export const COURSES_CHANGE_COURSE_FORM = 'COURSES_CHANGE_COURSE_FORM'
export const COURSES_OPEN_COURSE_MODAL_TO_EDIT = 'COURSES_OPEN_COURSE_MODAL_TO_EDIT'
export const COURSES__SET_LIST = 'COURSES__SET_LIST'

export function startLoading() {
  return { type: COURSES__START_LOADING }
}

export function stopLoading() {
  return { type: COURSES__STOP_LOADING }
}

export function setList(data) {
  return {
    type: COURSES__SET_LIST,
    payload: data
  }
}

export function addCourses(data) {
  return {
    type: COURSES_ADD_COURSE_TO_LIST,
    payload: data
  }
}
export function removeCourse(courseId) {
  return {
    type: COURSES_REMOVE_COURSE_FROM_LIST,
    payload: courseId
  }
}
export function openCourseModal() {
  return { type: COURSES_OPEN_COURSE_MODAL }
}
export function closeCourseModal() {
  return { type: COURSES_CLOSE_COURSE_MODAL }
}
export function startCouseModalLoading() {
  return { type: COURSES_START_COURSE_MODAL_LOADING }
}

export function stopCourseModalLoading() {
  return { type: COURSES_STOP_COURSE_MODAL_LOADING }
}
export function changeCourseForm(form) {
  return {
    type: COURSES_CHANGE_COURSE_FORM,
    payload: form
  }
}
export function openCourseModalToEdit(course) {
  return {
    type: COURSES_OPEN_COURSE_MODAL_TO_EDIT,
    payload: course
  }
}

export function getCourses(coursePackageIds = null, includeTest = true, listAll = true) {
  return function (dispatch) {
    dispatch(startLoading())
    return service
      .getCourses(coursePackageIds, includeTest, listAll)
      .then((resp) => {
        dispatch(setList(resp.data))
        dispatch(stopLoading())
      })
      .catch(() => message.error('Ocorreu um erro'))
  }
}

export function createCourse(form) {
  return function (dispatch) {
    service
      .createCourse(form)
      .then(() => {
        message.success('Curso criado com sucesso')
        dispatch(closeCourseModal())
        dispatch(getCourses([form.coursePackageId], true))
      })
      .catch((error) => {
        message.error(error.response.data.error)
      })
  }
}

export function saveCourse(form) {
  return function (dispatch) {
    service
      .saveCourse(form)
      .then(() => {
        message.success('Alterações salvas sucesso')
        dispatch(closeCourseModal())
        dispatch(addCourses(form))
      })
      .catch((error) => {
        console.log(error)
        message.error(error.response?.data.error)
      })
  }
}

export const deleteCourse = (courseId) => (dispatch) => {
  dispatch(startLoading())
  return service
    .deleteCourse(courseId)
    .then(() => {
      message.success('Curso deletado com sucesso!')
      dispatch(removeCourse(courseId))
    })
    .catch((error) => {
      message.error(error.response.data.error)
    })
    .finally(() => {
      dispatch(stopLoading())
    })
}