import React, { useEffect } from "react";
import { Table, Button } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

import styles from "./PlansGrid.module.scss";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  changeForm,
  deletePlan,
  getPlans,
  openModal,
} from "../../redux/Plans/Plans.action";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import PlanForm from "../PlanForm/PlanForm";
import Header from "../../containers/Header";
import AdmContainer from "../AdmContainer/AdmContainer";
import ControlPanelMenu from "../ControlPanelMenu/ControlPanelMenu";
import Footer from "../../containers/Footer";

const dateFormat = "DD/MM/YYYY";

export default function PlansGrid({ location }) {
  const list = useSelector((state) => state.plans.list);
  const modal = useSelector((state) => state.plans.modal);
  const loading = useSelector((state) => state.plans.loading);
  const dispatch = useDispatch();

  const onChangeForm = (value) => {
    dispatch(changeForm({ ...modal.form, ...value }));
  };

  const handleDeleteCourse = (planId) => {
    confirm({
      title: "Tem certeza que deseja excluir este pacote?",
      icon: <ExclamationCircleOutlined />,
      content: "Esta ação não poderá ser revertida",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => dispatch(deletePlan(planId)),
    });
  };

  const handleOpenCourseModal = (editMode, data) =>
    dispatch(openModal(editMode, data));

  const columns = [
    {
      title: "Nome do pacote",
      dataIndex: "name",
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      render: (tags) =>
        tags && tags.length
          ? tags.map((tag, index) => (
              <div className="tag" key={index}>
                {tag}
              </div>
            ))
          : "-",
    },
    {
      title: "Preço total",
      dataIndex: "fullPrice",
      render: (value) =>
        `R$ ${value.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}`,
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      render: (value) => moment(value).format(dateFormat),
    },
    {
      title: "Atualizado em",
      dataIndex: "updatedAt",
      render: (value) => moment(value).format(dateFormat),
    },
    {
      title: "Ações",
      dataIndex: "id",
      render: (id, plan) => (
        <div className="tableActions">
          <Link to={`/control-panel/plan/${id}`} className="btn">
            Ver
          </Link>
          <button
            onClick={() => handleOpenCourseModal(true, plan)}
            className="btn"
          >
            Editar
          </button>
          <button onClick={() => handleDeleteCourse(id)} className="btn">
            Excluir
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  return (
    <>
      <Header />
      <AdmContainer>
        <ControlPanelMenu menuActiveKey="plans" />
        <div className={styles.PlansGrid}>
          <div className="pageTitle">
            <Breadcrumb title="Pacotes" location={location} />
            <h1>Pacotes</h1>
          </div>
          <div className="actions">
            <Button type="primary" onClick={() => handleOpenCourseModal(false)}>
              Novo pacote
            </Button>
          </div>
          <Table dataSource={list} columns={columns} loading={loading} />
          {modal.show && (
            <PlanForm
              form={modal.form}
              onChangeForm={onChangeForm}
              editMode={modal.editMode}
              loading={modal.loading}
            />
          )}
        </div>
      </AdmContainer>
      <Footer />
    </>
  );
}
