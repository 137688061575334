export const USER_DETAIL_NAME =
  'USER_DETAIL_NAME'
export const USER_DETAIL_EMAIL =
  'USER_DETAIL_EMAIL'

export function userDetailName(name) {
  return { type: USER_DETAIL_NAME, payload: name }
}

export function userDetailEmail(email) {
  return { type: USER_DETAIL_EMAIL, payload: email }
}
