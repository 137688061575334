import React from "react";

import styles from "./Field.module.scss";

const Field = (props) => {
  const { children, label, required, marginBottom } = props;

  return (
    <div className={styles.Field} style={{ marginBottom }}>
      <span className={required ? "label required" : "label"}>{label}</span>
      <div className="field">{children}</div>
    </div>
  );
};

export default Field;
