import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "./Header";
import Footer from "./Footer";
import history from "../utils/history";

import { getPlan } from "../redux/Plans/Plans.action";
import AssociateCourseForm from "./AssociateCourseForm";
import ControlPanelMenu from "../components/ControlPanelMenu/ControlPanelMenu";
import AdmContainer from "../components/AdmContainer/AdmContainer";
import { getCourses } from "../redux/Courses/Courses.action";
import CoursesTable from "../components/CoursesTable/CoursesTable";

export default function PlanConfig({ location, match }) {
  const dispatch = useDispatch();
  const coursesPlanModalData = useSelector(
    (state) => state.controlPanel.coursesPlanModalData
  );
  const coursesList = useSelector((state) => state.courses.coursesList);
  const planDetails = useSelector((state) => state.plans.planDetails);

  useEffect(() => {
    const planId = match.params.id;
    if (!planId) {
      history.push("/control-panel");
    }
    dispatch(getCourses([planId], false, false));
    dispatch(getPlan(planId));
  }, [match.params.id]);

  return (
    <>
      <Header />
      <AdmContainer>
        <ControlPanelMenu menuActiveKey="plans" />
        <CoursesTable location={location} />
      </AdmContainer>
      <Footer />

      {coursesPlanModalData.show && (
        <AssociateCourseForm
          coursesList={coursesList}
          planDetails={planDetails}
        />
      )}
    </>
  );
}
