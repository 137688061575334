import { message } from 'antd'

import * as service from '../../services/plans.service'

export const PLANS__START_LOADING = 'PLANS__START_LOADING'
export const PLANS__STOP_LOADING = 'PLANS__STOP_LOADING'
export const PLANS__SET_LIST = 'PLANS__SET_LIST'
export const PLANS__OPEN_MODAL = 'PLANS__OPEN_MODAL'
export const PLANS__CLOSE_MODAL = 'PLANS__CLOSE_MODAL'
export const PLANS__CHANGE_FORM = 'PLANS__CHANGE_FORM'
export const PLANS__START_MODAL_LOADING = 'PLANS__START_MODAL_LOADING'
export const PLANS__STOP_MODAL_LOADING = 'PLANS__STOP_MODAL_LOADING'
export const PLANS__ADD_NEW_PLAN = 'PLANS__ADD_NEW_PLAN'
export const PLANS__REMOVE_PLAN_FROM_GRID = 'PLANS__REMOVE_PLAN_FROM_GRID'
export const PLANS__UPDATE_PLAN = 'PLANS__UPDATE_PLAN'
export const PLANS__SET_PLAN_DETAILS = 'PLANS__SET_PLAN_DETAILS'

export function startLoading () {
  return { type: PLANS__START_LOADING }
}

export function stopLoading () {
  return { type: PLANS__STOP_LOADING }
}

export function setList (data) {
  return {
    type: PLANS__SET_LIST,
    payload: data
  }
}

export function getPlans () {
  return function (dispatch) {
    dispatch(startLoading())
    return service
      .getPlans()
      .then((resp) => {
        dispatch(setList(resp.data))
        dispatch(stopLoading())
      })
      .catch(() => message.error('Ocorreu um erro'))
  }
}

export const openModal = (editMode = false, data) => ({
  type: PLANS__OPEN_MODAL,
  payload: { editMode, data }
})

export const closeModal = () => ({
  type: PLANS__CLOSE_MODAL
})

export const changeForm = (form) => ({
  type: PLANS__CHANGE_FORM,
  payload: form
})

export const startModalLoading = () => ({
  type: PLANS__START_MODAL_LOADING
})

export const stopModalLoading = () => ({
  type: PLANS__STOP_MODAL_LOADING
})

export const addNewPlan = (plan) => ({
  type: PLANS__ADD_NEW_PLAN,
  payload: plan
})

export const createPlan = (form) => (dispatch) => {
  dispatch(startModalLoading())
  return service
    .createPlan(form)
    .then((resp) => {
      message.success('Pacote criado com sucesso!')
      dispatch(closeModal())
      dispatch(addNewPlan(resp.data))
    })
    .finally(() => {
      dispatch(stopModalLoading())
    })
}

export const associateCourseToPlan = (planId, courseId) => (dispatch) => {
  dispatch(startModalLoading())
  return service
    .associateCourseToPlan(planId, courseId)
    .then((resp) => {
      message.success('Curso associado com sucesso!')
      dispatch(getPlan(planId))
    })
    .finally(() => {
      dispatch(stopModalLoading())
    })
}

export const removePlanFromGrid = (planId) => ({
  type: PLANS__REMOVE_PLAN_FROM_GRID,
  payload: planId
})

export const deletePlan = (planId) => (dispatch) => {
  dispatch(startLoading())
  return service
    .deletePlan(planId)
    .then(() => {
      dispatch(removePlanFromGrid(planId))
      message.success('Pacote removido com sucesso')
    })
    .finally(() => {
      dispatch(stopLoading())
    })
}

export const updatePlan = (plan) => ({
  type: PLANS__UPDATE_PLAN,
  payload: plan
})

export const saveEditedPlan = (form) => (dispatch) => {
  dispatch(startModalLoading())
  return service
    .saveEditedPlan(form)
    .then((resp) => {
      message.success('Pacote editado com sucesso!')
      dispatch(closeModal())
      dispatch(updatePlan(resp.data))
    })
    .finally(() => {
      dispatch(stopModalLoading())
    })
}

export const setPlanDetails = (data) => ({
  type: PLANS__SET_PLAN_DETAILS,
  payload: data
})

export const getPlan = (planId) => (dispatch) => {
  dispatch(startLoading())
  return service
    .getPlan(planId)
    .then((resp) => {
      dispatch(setPlanDetails(resp.data))
    })
    .finally(() => {
      dispatch(stopLoading())
    })
}
