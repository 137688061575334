import React from 'react'

import './styles/app.scss'
import './styles/theme.less'
import { useSelector } from 'react-redux'

import RouteConfig from './routes'

function App (props) {
  const { location } = props
  const logged = useSelector((state) => state.session.logged)

  return (
    <div className="App">
      <RouteConfig location={location} logged={logged} />
    </div>
  )
}

export default App
