import React from 'react'
import { connect } from 'react-redux'

import Header from './Header'
import Footer from './Footer'
import SiteContainer from '../components/SiteContainer/SiteContainer'
import ProfileForm from '../components/ProfileForm/ProfileForm'

class Profile extends React.Component {
  render () {
    const {
      userData
    } = this.props

    return (
      <>
        <Header />
        <SiteContainer>
          <ProfileForm
            user={userData.user}
          />
        </SiteContainer>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = state => ({
  userData: state.session.userData
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
