import React, { useState } from "react";
import { Modal, Input, Select } from "antd";
const { Option } = Select;
import styles from "./QuestionConfigModal.module.scss";
import Field from "../Field/Field";
import { useDispatch, useSelector } from "react-redux";
import {
  changeQuestionForm,
  closeQuestionModal,
  createQuestion,
  editQuestion,
} from "../../redux/Class/Class.action";
import Question from "../Question/Question";

export default function QuestionConfigModal() {
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.class.classes);
  const loading = useSelector((state) => state.class.questionModal.loading);
  const isEdit = useSelector((state) => state.class.questionModal.isEdit);
  const data = useSelector((state) => state.class.questionModal.data);
  const [classroom, setClassroom] = useState(classes[0]?.id);
  const [section1, setSection1] = useState(data[0]);
  const [section2, setSection2] = useState(data[1]);
  const [section3, setSection3] = useState(data[2]);
  const [section4, setSection4] = useState(data[3]);
  const [section5, setSection5] = useState(data[4]);
  const handleCloseQuestionModal = () => dispatch(closeQuestionModal());
  const handleCreateQuestion = () => {
    dispatch(
      createQuestion({
        classId: classroom,
        questions: [section1, section2, section3, section4, section5],
      })
    );
  };
  const handleEditQuestion = () => {
    dispatch(
      editQuestion({
        classId: classroom,
        questions: [section1, section2, section3, section4, section5],
      })
    );
  };

  return (
    <Modal
      className={styles.QuestionConfigModal}
      visible
      title={isEdit ? "Editar questões" : "Criar questões"}
      loading={loading}
      onCancel={handleCloseQuestionModal}
      cancelText="Cancelar"
      okText="Salvar"
      onOk={isEdit ? handleEditQuestion : handleCreateQuestion}
      okButtonProps={{
        loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
    >
      <Field label="Aula" required>
        <Select
          defaultValue={classroom}
          style={{ width: "100%" }}
          onChange={(value) => setClassroom(value)}
        >
          {classes.map((classroom) => (
            <Option value={classroom.id}>{classroom.title}</Option>
          ))}
        </Select>
      </Field>
      <Question
        questionNumber={1}
        section={section1}
        setSection={setSection1}
      />
      <Question
        questionNumber={2}
        section={section2}
        setSection={setSection2}
      />
      <Question
        questionNumber={3}
        section={section3}
        setSection={setSection3}
      />
      <Question
        questionNumber={4}
        section={section4}
        setSection={setSection4}
      />
      <Question
        questionNumber={5}
        section={section5}
        setSection={setSection5}
      />
    </Modal>
  );
}
