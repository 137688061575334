import { httpHelper } from '../utils/http-helper'

export function login(email, password) {
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }

  return httpHelper.post(headers, { email, password }, 'users/sessions')
}
