import React, { useEffect } from "react";
import { message, Modal, Input, Select } from "antd";

import {
  changeCourseForm,
  closeCourseModal,
  createCourse,
  saveCourse,
} from "../redux/Courses/Courses.action";
import { getPlans } from "../redux/Plans/Plans.action";
import { useDispatch, useSelector } from "react-redux";
import Field from "../components/Field/Field";
import UploadImage from "../components/UploadImage";

const { TextArea } = Input;
const { Option } = Select;

export default function CourseForm() {
  const courseModalData = useSelector((state) => state.courses.courseModalData);
  const planDetails = useSelector((state) => state.plans.planDetails);
  const getPackages = useSelector((state) => state.plans.list);
  const dispatch = useDispatch();
  const handleCloseModal = () => dispatch(closeCourseModal());

  const handleCreateCourse = () => {
    const form = courseModalData.form;
    if (!form.name || !form.description || !form.coursePackageId) {
      return message.warning("Preencha todos os campos obrigatórios");
    }

    return dispatch(createCourse(form));
  };

  const handleSaveCourse = () => {
    const form = courseModalData.form;
    if (!form.name || !form.description) {
      return message.warning("Preencha todos os campos obrigatórios");
    }

    return dispatch(
      saveCourse({
        ...form,
        coursePackageId: planDetails?.id,
      })
    );
  };

  const handleChangeCourseForm = (value) => {
    dispatch(
      changeCourseForm({
        ...courseModalData.form,
        ...value,
      })
    );
  };

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  return (
    <Modal
      visible
      onCancel={handleCloseModal}
      title={courseModalData.editingMode ? "Editar Curso" : "Criar Curso"}
      okText={courseModalData.editingMode ? "Salvar Curso" : "Criar Curso"}
      cancelText="Cancelar"
      onOk={courseModalData.editingMode ? handleSaveCourse : handleCreateCourse}
    >
      <UploadImage />
      <Field label="Nome" required>
        <Input
          value={courseModalData.form.name}
          onChange={(el) => handleChangeCourseForm({ name: el.target.value })}
        />
      </Field>

      <Field label="Descrição" required>
        <TextArea
          rows={2}
          value={courseModalData.form.description}
          onChange={(el) =>
            handleChangeCourseForm({ description: el.target.value })
          }
        />
      </Field>
      <Field label="Pacote" required>
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Selecione um pacote"
          optionFilterProp="children"
          onChange={(el) => handleChangeCourseForm({ coursePackageId: el })}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {getPackages &&
            getPackages.map((course) => (
              <Option key={course.id} value={course.id}>
                {course.name}
              </Option>
            ))}
        </Select>
      </Field>
    </Modal>
  );
}
