import Immutable from "seamless-immutable";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage-helper";

import * as actions from "./PurchasePlan.action";

const initialState = () =>
  Immutable({
    loading: false,
    form: {},
    formStep: 0,
    plan: getLocalStorageItem("wjschoolSelectedPurchasePlan") || {},
  });

export default function purchasePlanReducer(state = initialState(), action) {
  switch (action.type) {
    case actions.PURCHASE_PLAN__START_LOADING:
      return state.set("loading", true);
    case actions.PURCHASE_PLAN__STOP_LOADING:
      return state.set("loading", false);
    case actions.PURCHASE_PLAN__NEXT_STEP:
      return state.set("formStep", state.formStep + 1);
    case actions.PURCHASE_PLAN__PREVIOUS_STEP:
      return state.set("formStep", state.formStep - 1);
    case actions.PURCHASE_PLAN__CHANGE_FORM:
      return state.set("form", { ...state.form, ...action.payload });
    case actions.PURCHASE_SET_PLAN:
      setLocalStorageItem(action.payload, "wjschoolSelectedPurchasePlan");
      return state.set("plan", action.payload);
    default:
      return state;
  }
}
