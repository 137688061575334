import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { HomeOutlined } from "@ant-design/icons";

import styles from "./Breadcrumb.module.scss";
const breadcrumbNameMap = (item) => {
  const map = {
    "control-panel": "Painel de controle",
    courses: "Cursos",
    course: "Curso",
    users: "Usuários",
    plan: "Pacotes",
  };

  return map[item] ? map[item] : item;
};
const WJBreadcrumb = ({ location }) => {
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((item, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const routesMapped = breadcrumbNameMap(item);
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{routesMapped}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined />
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <div className={styles.WJBreadcrumb}>
      <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
    </div>
  );
};

WJBreadcrumb.defaultProps = {
  title: "",
  companyGroupName: "",
};

WJBreadcrumb.propTypes = {
  title: PropTypes.string,
  location: PropTypes.object.isRequired,
  breadcrumbNameMap: PropTypes.func.isRequired,
  companyGroupName: PropTypes.string,
};

export default WJBreadcrumb;
