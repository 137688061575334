import React from 'react'
import { connect } from 'react-redux'

import TopBar from '../components/TopBar/TopBar'
import { logout } from '../redux/Session/Session.action'

class Header extends React.Component {
  // componentDidMount() {
  //   const { fromHome } = this.props;
  //   if (fromHome) {
  //     window.addEventListener('scroll', this.handleScroll);
  //   }
  // }

  // componentWillUnmount() {
  //   const { fromHome } = this.props;
  //   if (fromHome) {
  //     window.addEventListener('scroll', this.handleScroll);
  //   }
  // }

  // handleScroll = () => {
  //   const { showBackground } = this.state;
  //   let scrollTop = window.scrollY;

  //   if (scrollTop >= 100 && !showBackground) {
  //     this.setState({
  //       showBackground: true,
  //     });
  //   } else if (scrollTop < 100 && showBackground) {
  //     this.setState({
  //       showBackground: false,
  //     });
  //   }
  // }

  render () {
    const {
      handleLogout,
      logged,
      userData,
      isHome
    } = this.props

    return (
      <TopBar
        logged={logged}
        userData={userData}
        logout={handleLogout}
        isHome={isHome}
      />
    )
  }
}

const mapStateToProps = state => ({
  userData: state.session.userData,
  logged: state.session.logged
})

const mapDispatchToProps = dispatch => ({
  handleLogout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
