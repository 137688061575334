import React from 'react'
import { Input, Radio, DatePicker, Button, Col, Row } from 'antd'
import moment from 'moment'
import styles from './ProfileForm.module.scss'
import { connect } from 'react-redux'
import { updateUser } from '../../redux/User/User.actions'

const dateFormat = 'DD/MM/YYYY'

class ProfileForm extends React.Component {
  state = {
    name: this.props.user.name,
    birthdate: this.props.user.birthdate,
    gender: this.props.user.gender
  }

  handleSubmitButton = async () => {
    this.props.handleUpdateUser(this.state)
  }

  handleChangeInput = (type, value) => {
    switch (type) {
      case 'name':
        this.setState({
          ...this.state,
          name: value
        })
        break
      case 'oldPassword':
        this.setState({
          ...this.state,
          oldPassword: value
        })
        break
      case 'password':
        this.setState({
          ...this.state,
          password: value
        })
        break
      case 'confirmPassword':
        this.setState({
          ...this.state,
          confirmPassword: value
        })
        break
      case 'birthdate':
        this.setState({
          ...this.state,
          birthdate: value
        })
        break
      case 'gender':
        this.setState({
          ...this.state,
          gender: value
        })
        break
      default:
        break
    }
  }

  render () {
    const { user } = this.props
    console.log(user)
    return (
      <Col className={styles.profileColContainer}>
        <div className={styles.ProfileForm}>
          <div className="box">
            <div className="field">
              <span className="label">Nome</span>
              <Input
                defaultValue={user.name}
                placeholder="Digite o seu nome"
                onChange={(e) => this.handleChangeInput('name', e.target.value)}
              />
            </div>
            <div className="field">
              <span className="label">Data de nascimento</span>
              <DatePicker
                format={dateFormat}
                defaultValue={moment(user.birthdate, 'YYYY-MM--DD')}
                onChange={(value) => this.handleChangeInput('birthdate', value.format())}
                style={{ width: '100%' }}
                disabledDate={current => moment() <= current}
                placeholder="Digite sua data de nascimento"
              />
            </div>
            <div className="field">
              <span className="label">Sexo</span>
              <Radio.Group
                defaultValue={user.gender}
                onChange={(e) => this.handleChangeInput('gender', e.target.value)}
              >
                <Radio value={1}>Masculino</Radio>
                <Radio value={2}>Feminino</Radio>
              </Radio.Group>
            </div>
          </div>

          <div className="box">
            <div className="field">
              <span className="label">Senha atual</span>
              <Input
                type="password"
                placeholder="Digite sua senha atual"
                style={{ width: '100%' }}
                size="large"
                onChange={(e) => this.handleChangeInput('oldPassword', e.target.value)}
              />
            </div>
            <div className="field">
              <span className="label">Nova senha</span>
              <Input
                type="password"
                placeholder="Digite senha nova"
                style={{ width: '100%' }}
                size="large"
                onChange={(e) => this.handleChangeInput('password', e.target.value)}
              />
            </div>
            <div className="field">
              <span className="label">Confirme nova senha</span>
              <Input
                type="password"
                placeholder="Confirme a nova senha"
                style={{ width: '100%' }}
                size="large"
                onChange={(e) => this.handleChangeInput('confirmPassword', e.target.value)}
              />
            </div>
          </div>
        </div>
        <Row>
          <Button
            type="primary"
            className={styles.saveButton}
            onClick={this.handleSubmitButton}
          >
            Salvar
          </Button>
        </Row>

      </Col>

    )
  }
}
const mapStateToProps = (state) => ({
  usersLoading: state.user.loading
})
const mapDispatchToProps = (dispatch) => ({
  handleUpdateUser: (form) => dispatch(updateUser(form))
})
export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)
