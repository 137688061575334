import React from "react";
import { Row, Col, Input } from "antd";
import styles from "./PaymentInformationForm.module.scss";
import { MaskedInput } from "antd-mask-input";

function PaymentInformationForm({ form, onChangeForm }) {
  return (
    <>
      {form && (
        <div className={styles.PaymentInformationForm}>
          <div className="form">
            <div className="item">
              <span className="label">Nome do Titular</span>
              <Input
                placeholder="Nome de acordo com o cartao"
                style={{ width: "100%" }}
                size="large"
                value={form.creditCardName}
                onChange={(el) =>
                  onChangeForm({ creditCardName: el.target.value })
                }
              />
            </div>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col span={11}>
                <div className="item">
                  <span className="label">Número do cartão</span>
                  <MaskedInput
                    placeholder="**** **** **** ****"
                    mask={"0000 0000 0000 0000"}
                    maskOptions={{
                      lazy: false,
                    }}
                    style={{ width: "100%" }}
                    size="large"
                    value={form.creditCardNumber}
                    onChange={(el) =>
                      onChangeForm({ creditCardNumber: el.unmaskedValue })
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="item">
                  <span className="label">Vencimento</span>
                  <MaskedInput
                    mask={"00/0000"}
                    maskOptions={{
                      lazy: false,
                    }}
                    placeholder="MM/AAAA"
                    style={{ width: "100%" }}
                    size="large"
                    value={form.creditCardExpireDate}
                    onChange={(el) =>
                      onChangeForm({ creditCardExpireDate: el.maskedValue })
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="item">
                  <span className="label">CVV</span>
                  <MaskedInput
                    mask={"000"}
                    maskOptions={{
                      lazy: false,
                    }}
                    placeholder="CVV"
                    style={{ width: "100%" }}
                    size="large"
                    value={form.creditCardCVV}
                    onChange={(el) =>
                      onChangeForm({ creditCardCVV: el.unmaskedValue })
                    }
                    max="3"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentInformationForm;
