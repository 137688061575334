import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ControlPanelMenu from "../components/ControlPanelMenu/ControlPanelMenu";
import AdmContainer from "../components/AdmContainer/AdmContainer";
import UserProgression from "../components/UserProgression/UserProgression";
import CourseProgression from "../components/CourseProgression/CourseProgression";

export default function CoursesDetails({ location }) {
  console.log(location);
  return (
    <>
      <Header />
      <AdmContainer>
        <ControlPanelMenu menuActiveKey="users" />
        <CourseProgression
          location={location}
          userId={location.state.userId}
          course={location.state.course}
        />
      </AdmContainer>
      <Footer />
    </>
  );
}
