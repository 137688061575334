import React from "react";
import { Button, Modal, Table } from "antd";
import ClassConfigModal from "../ClassConfigModal/ClassConfigModal";
import styles from "./ClassesConfigList.module.scss";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClass,
  openClassModal,
  openQuestionModal,
  openQuestionModalEdit,
} from "../../redux/Class/Class.action";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import QuestionConfigModal from "../QuestionConfigModal/QuestionConfigModal";

const { confirm } = Modal;

export default function ClassesConfigList({ location, courseId }) {
  const dispatch = useDispatch();
  const classModal = useSelector((state) => state.class.classModal);
  const questionModal = useSelector((state) => state.class.questionModal);
  const classes = useSelector((state) => state.class.classes);
  const loading = useSelector((state) => state.class.loading);
  const handleDeleteClass = (classId) => {
    confirm({
      title: "Tem certeza que deseja excluir esta aula?",
      icon: <ExclamationCircleOutlined />,
      content: "Esta ação não poderá ser revertida",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => dispatch(deleteClass(classId)),
    });
  };
  const handleOpenClassModal = () => dispatch(openClassModal());
  const handleOpenClassQuestionModal = () => dispatch(openQuestionModal());
  const handleClickEditQuestions = (value) => {
    dispatch(openQuestionModalEdit(value));
  };

  const columns = [
    {
      title: "Aula",
      dataIndex: "title",
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Duração",
      dataIndex: "duration",
      render: (value, row) => Math.floor(value) + " m",
    },
    {
      title: "Vídeo",
      dataIndex: "videoUrl",
      render: (value, row) => (
        <a href={value} target="_blank" rel="noopener noreferrer">
          clique aqui!
        </a>
      ),
    },
    {
      title: "Anexo",
      dataIndex: "attachments",
      render: (value, row) => {
        if (value.length > 0) {
          return (
            <a href={value[0].url} target="_blank" rel="noopener noreferrer">
              clique aqui!
            </a>
          );
        }

        return <span>Não cadastrado</span>;
      },
    },
    {
      title: "Questões",
      dataIndex: "questions",
      render: (value, row) => {
        if (value.length > 0) {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleClickEditQuestions(value)}
            >
              Cadastrado
            </span>
          );
        }

        return <span>Não cadastrado</span>;
      },
    },
    {
      title: "Ações",
      dataIndex: "id",
      render: (value, row) => (
        <div className="actions">
          {/* <button
            onClick={() => handleOpenClassModalToEdit(row)}
          >
            Editar
          </button> */}
          <button onClick={() => handleDeleteClass(value)}>Excluir</button>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.ClassesConfigList}>
      <div className="buttons">
        <Breadcrumb location={location} />
        <div>
          <Button type="primary" onClick={handleOpenClassModal}>
            Nova aula
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: 20 }}
            onClick={handleOpenClassQuestionModal}
          >
            Associar questões
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={classes}
        className="classesGrid"
        loading={loading}
      />
      {classModal.show && (
        <ClassConfigModal courseId={courseId} {...classModal} />
      )}
      {questionModal.show && (
        <QuestionConfigModal courseId={courseId} {...questionModal} />
      )}
    </div>
  );
}
