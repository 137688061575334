import React from "react";
import * as Yup from "yup";
import validator from "validator";
import { message } from "antd";
import styles from "./PurchasePlanContainer.module.scss";
import SiteContainer from "../SiteContainer/SiteContainer";
import PurchasePlanForm from "../PurchasePlanForm/PurchasePlanForm";
import { PurchasePlanResume } from "../PurchasePlanResume/PurchasePlanResume";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  changeForm,
  nextStep,
  previousStep,
  purchasePlan,
} from "../../redux/PurchasePlan/PurchasePlan.action";

export function PurchasePlanContainer({ match }) {
  const dispatch = useDispatch();
  const formStep = useSelector((state) => state.purchasePlan.formStep);
  const form = useSelector((state) => state.purchasePlan.form);
  const next = () => dispatch(nextStep());
  const prev = () => dispatch(previousStep());
  const handleChangeForm = (formData) => dispatch(changeForm(formData));
  const handlePurchasePlan = (formData) => dispatch(purchasePlan(formData));

  const onChangeForm = (value) => {
    handleChangeForm({
      ...form,
      ...value,
    });
  };

  const onSubmitForm = (form) => {
    const { id: planId } = match.params;
    Yup.addMethod(Yup.string, "creditCardNumber", function (errorMessage) {
      return this.test("test-card-number", errorMessage, function (value) {
        const { path, createError } = this;

        return (
          validator.isCreditCard(value) ||
          createError({ path, message: errorMessage })
        );
      });
    });
    Yup.addMethod(Yup.string, "creditCardExpireDate", function (errorMessage) {
      return this.test("test-card-expire-date", errorMessage, function (value) {
        const { path, createError } = this;
        const [month, year] = value.split("/");
        const today = new Date();
        const cardDate = new Date(year, month, today.getDate());
        return (
          today.getTime() < cardDate.getTime() ||
          createError({ path, message: errorMessage })
        );
      });
    });
    const schema = Yup.object().shape({
      creditCardCVV: Yup.string().required("CVV é um campo obrigatório."),
      creditCardExpireDate: Yup.string()
        .required("Vencimento é um campo obrigatório.")
        .creditCardExpireDate("Vencimento inválido"),
      creditCardNumber: Yup.string()
        .required("Número do cartão é um campo obrigatório.")
        .creditCardNumber("Número do cartão inválido"),
      creditCardName: Yup.string().required(
        "Nome do Titular é um campo obrigatório."
      ),
    });
    try {
      schema.validateSync(form, { abortEarly: true });
      handlePurchasePlan({ ...form, planId });
    } catch (error) {
      console.log(error.message);
      message.error(error.message);
    }
  };

  const isAbleToProceed = () => {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("Informe um email valido")
        .required("Email é um campo obrigatório."),
      cpf: Yup.string().required("CPF é um campo obrigatório."),
      number: Yup.number("Digite um número válido").required(
        "Número é um campo obrigatório."
      ),
      complement: Yup.string(),
      district: Yup.string().required("Bairro é um campo obrigatório."),
      paymentAddress: Yup.string().required("Endereço é um campo obrigatório."),
      city: Yup.string().required("Cidade é um campo obrigatório."),
      state: Yup.string().required("Estado é um campo obrigatório."),
      cep: Yup.string().required("CEP é um campo obrigatório."),
    });
    try {
      schema.validateSync(form, { abortEarly: true });
      next();
    } catch (error) {
      console.log(error.message);
      message.error(error.message);
    }
  };
  return (
    <div className={styles.PurchasePlanContainer}>
      <SiteContainer>
        <section className="paymentGroup">
          <PurchasePlanForm
            form={form}
            step={formStep}
            next={isAbleToProceed}
            prev={prev}
            onChangeForm={onChangeForm}
            onSubmitForm={onSubmitForm}
          />
          <PurchasePlanResume />
        </section>
      </SiteContainer>
    </div>
  );
}
