import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import Header from './Header'
import Footer from './Footer'
import RegisterForm from '../components/RegisterForm/RegisterForm'
import { registerUser } from '../redux/User/User.actions'
import { message } from 'antd'

class Register extends React.Component {
  state = {
    form: {
      name: '',
      email: '',
      password: '',
      nickname: '',
      birthdate: '',
      gender: 1,
      agreeTerms: false
    }
  }

  onChangeForm = (value) => {
    const { form } = this.state
    this.setState({
      ...this.state,
      form: {
        ...form,
        ...value
      }
    })
  }

  onSubmitForm = (e) => {
    e.preventDefault()
    const { form } = this.state
    const { handleRegisterUser } = this.props
    const schema = Yup.object().shape({
      birthdate: Yup.date().required('Data de nascimento é um campo obrigatório.'),
      password: Yup.string()
        .required('Senha é um campo obrigatório.')
        .min(6, 'A senha deve ter um mínimo de 6 digitos'),
      email: Yup.string()
        .email('Informe um email valido')
        .required('Email é um campo obrigatório.'),
      name: Yup.string().required('Nome é um campo obrigatório.'),
      gender: Yup.number().required('Sexo é um campo obrigatório.')
    })
    try {
      schema.validateSync(form, { abortEarly: true })
      handleRegisterUser(form)
    } catch (error) {
      console.log(error.message)
      message.error(error.message)
    }
  }

  render () {
    const { loading } = this.props
    const { form } = this.state

    return (
      <>
        <Header fromHome />
        <RegisterForm
          form={form}
          onChangeForm={this.onChangeForm}
          onSubmitForm={this.onSubmitForm}
          loading={loading}
        />
        <Footer />
      </>
    )
  }
}

const mapStateToProps = state => ({
  // loading: state.purchasePlan.loading,
})

const mapDispatchToProps = dispatch => ({
  handleRegisterUser: form => dispatch(registerUser(form))
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
