import React from 'react'

import styles from './SiteContainer.module.scss'

export default function SiteContainer (props) {
  const { children } = props

  return (
    <div className={styles.SiteContainer}>
      { children }
    </div>
  )
}
