import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { history } from "./utils/history";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Dashboard from "./containers/Dashboard";
import PurchasePlan from "./containers/PurchasePlan";
import Register from "./containers/Register";
import Course from "./containers/Course";
import CourseConfig from "./containers/CourseConfig";
import Profile from "./containers/Profile";
import PlanConfig from "./containers/PlanConfig";
import ScrollToTop from "./utils/scroll-to-top";
import PlansGrid from "./components/PlansGrid/PlansGrid";
import CPCoursesList from "./components/CPCoursesList/CPCoursesList";
import UsersGrid from "./components/UsersGrid/UsersGrid";
import UserDetails from "./containers/UserDetails";
import CoursesDetails from "./containers/CoursesDetails";

const RouteConfig = (props) => {
  const { logged } = props;

  const NotFound = () => <h2>Page Not Found</h2>;

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        rest.logged ? <Component {...props} /> : <Login {...props} />
      }
    />
  );

  return (
    <div>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <Route
            path="/login"
            exact
            render={(props) => <Login {...props} />}
            logged={logged}
          />
          <Route
            path="/"
            exact
            render={(props) => <Home {...props} />}
            logged={logged}
          />
          <PrivateRoute
            path="/dashboard"
            exact
            component={Dashboard}
            logged={logged}
          />
          <Route exact path="/course/:id" component={Course} logged={logged} />
          <PrivateRoute
            exact
            path="/purchasePlan/:id"
            component={PurchasePlan}
            logged={logged}
          />
          <Route exact path="/register" component={Register} logged={logged} />
          <Route exact path="/profile" component={Profile} logged={logged} />
          <Route
            exact
            path="/control-panel/users"
            component={UsersGrid}
            logged={logged}
          />
          <Route
            exact
            path="/control-panel/users/:id"
            component={UserDetails}
            logged={logged}
          />
          <Route
            exact
            path="/control-panel/users/:id/course"
            component={CoursesDetails}
            logged={logged}
          />
          <Route
            exact
            path="/control-panel/courses"
            component={CPCoursesList}
            logged={logged}
          />
          <Route
            exact
            path="/control-panel/plan"
            component={PlansGrid}
            logged={logged}
          />
          <Route
            exact
            path="/control-panel/courses/:id"
            component={CourseConfig}
            logged={logged}
          />
          <Route
            exact
            path="/control-panel/plan/:id"
            component={PlanConfig}
            logged={logged}
          />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
};

export default RouteConfig;
