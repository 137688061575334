export const CONTROL_PANEL_OPEN_COURSES_PLAN_MODAL =
  'CONTROL_PANEL_OPEN_COURSES_PLAN_MODAL'
export const CONTROL_PANEL_CLOSE_COURSES_PLAN_MODAL =
  'CONTROL_PANEL_CLOSE_COURSES_PLAN_MODAL'
export const CONTROL_PANEL_START_LOADING = 'CONTROL_PANEL_START_LOADING'
export const CONTROL_PANEL_STOP_LOADING = 'CONTROL_PANEL_STOP_LOADING'

export function startLoading() {
  return { type: CONTROL_PANEL_START_LOADING }
}

export function stopLoading() {
  return { type: CONTROL_PANEL_STOP_LOADING }
}

export function openCoursesPlanModal() {
  return { type: CONTROL_PANEL_OPEN_COURSES_PLAN_MODAL }
}
export function closeCoursesPlanModal() {
  return { type: CONTROL_PANEL_CLOSE_COURSES_PLAN_MODAL }
}
