import Immutable from 'seamless-immutable'

import * as actions from './User.actions'

const initialState = () =>
  Immutable({
    loading: false,
    courses: [],
    list: []
  })

export default function userReducer (state = initialState(), action) {
  switch (action.type) {
    case actions.USER__START_LOADING:
      return state.set('loading', true)
    case actions.USER__STOP_LOADING:
      return state.set('loading', false)
    case actions.USER__SET_COURSES:
      return state.set('courses', action.payload)
    case actions.USER__SET_LIST:
      return state.set('list', action.payload)
    case actions.USER__REMOVE_USER_FROM_GRID:
      return state.set(
        'list',
        state.list.filter((el) => el.id !== action.payload)
      )
    default:
      return state
  }
}
