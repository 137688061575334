import React from "react";
import { Row, Col, Input } from "antd";
import styles from "./PersonalDataForm.module.scss";
import { MaskedInput } from "antd-mask-input";
import axios from "axios";

function PersonalDataForm({ form, onChangeForm }) {
  function OnChangeCep(value) {
    if (value.length === 8) {
      onChangeForm({
        cep: value,
      });
      axios
        .get(`https://viacep.com.br/ws/${value}/json/`)
        .then((response) => {
          onChangeForm({
            cep: value,
            paymentAddress: response.data.logradouro,
            district: response.data.bairro,
            complement: response.data.complemento,
            state: response.data.uf,
            city: response.data.localidade,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <>
      {form && (
        <div className={styles.PersonalDataForm}>
          <div className="form">
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col span={11}>
                <div className="item">
                  <span className="label">Nome completo</span>
                  <Input
                    placeholder="Digite seu nome completo"
                    style={{ width: "100%" }}
                    size="large"
                    value={form.name}
                    onChange={(el) => onChangeForm({ name: el.target.value })}
                  />
                </div>
              </Col>
              <Col span={11}>
                <div className="item">
                  <span className="label">CPF</span>
                  <MaskedInput
                    mask={"000.000.000-00"}
                    maskOptions={{
                      lazy: false,
                    }}
                    placeholder="Digite seu CPF"
                    style={{ width: "100%" }}
                    size="large"
                    value={form.cpf}
                    onChange={(el) => onChangeForm({ cpf: el.target.value })}
                  />
                </div>
              </Col>
            </Row>
            <div className="item">
              <span className="label">E-mail</span>
              <Input
                placeholder="Digite seu e-mail"
                style={{ width: "100%" }}
                size="large"
                value={form.email}
                onChange={(el) => onChangeForm({ email: el.target.value })}
              />
            </div>
            <div className="item">
              <span className="label">CEP</span>
              <MaskedInput
                mask={"00000-000"}
                maskOptions={{
                  lazy: false,
                }}
                placeholder="CEP"
                style={{ width: "100%" }}
                size="large"
                value={form.cep}
                onChange={(el) => OnChangeCep(el.unmaskedValue)}
              />
            </div>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col span={10}>
                <div className="item">
                  <span className="label">Endereço</span>
                  <Input
                    placeholder="Digite seu endereço"
                    style={{ width: "100%" }}
                    size="large"
                    value={form.paymentAddress}
                    // value={address}
                    onChange={(el) =>
                      onChangeForm({ paymentAddress: el.target.value })
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="item">
                  <span className="label">Número</span>
                  <Input
                    placeholder="Número"
                    style={{ width: "100%" }}
                    size="large"
                    value={form.number}
                    onChange={(el) => onChangeForm({ number: el.target.value })}
                  />
                </div>
              </Col>
              <Col>
                <div className="item">
                  <span className="label">Bairro</span>
                  <Input
                    placeholder="Bairro"
                    style={{ width: "100%" }}
                    size="large"
                    value={form.district}
                    onChange={(el) =>
                      onChangeForm({ district: el.target.value })
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col span={10}>
                <div className="item">
                  <span className="label">Complemento</span>
                  <Input
                    placeholder="Complemento"
                    style={{ width: "100%" }}
                    size="large"
                    value={form.complement}
                    onChange={(el) =>
                      onChangeForm({ complement: el.target.value })
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="item">
                  <span className="label">Cidade</span>
                  <Input
                    placeholder="Cidade"
                    style={{ width: "100%" }}
                    size="large"
                    value={form.city}
                    onChange={(el) => onChangeForm({ city: el.target.value })}
                  />
                </div>
              </Col>
              <Col>
                <div className="item">
                  <span className="label">Estado</span>
                  <Input
                    placeholder="Estado"
                    style={{ width: "100%" }}
                    size="large"
                    value={form.state}
                    onChange={(el) => onChangeForm({ state: el.target.value })}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export default PersonalDataForm;
