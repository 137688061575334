import React from "react";
import { Row, Col, Checkbox, Rate, Button } from "antd";
import moment from "moment";
import styles from "./Class.module.scss";
import CommentArea from "../CommentArea/CommentArea";
import { markClassAsDone } from "../../redux/Course/Course.action";
import { useDispatch, useSelector } from "react-redux";

function Class() {
  const dispatch = useDispatch();
  const selectedClass = useSelector((state) => state.course.selectedClass);
  const classData = useSelector((state) =>
    state.course.data.classes.find((c) => c.id === selectedClass.classId)
  );

  function handleAttachments() {
    window.open(classData.attachments[0].url, "_blank");
  }

  return (
    <div className={styles.Class}>
      {classData && (
        <Row>
          <Col span={18}>
            <div className="classTitle">{classData.title}</div>

            <div className="video">
              <video
                width="320"
                height="240"
                autoPlay
                controls
                key={
                  !classData.videoUrl.includes("https://")
                    ? `https://${classData.videoUrl}`
                    : classData.videoUrl
                }
              >
                <source
                  src={
                    !classData.videoUrl.includes("https://")
                      ? `https://${classData.videoUrl}`
                      : classData.videoUrl
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>

            {classData.description ? (
              <div className="description">{classData.description}</div>
            ) : (
              ""
            )}
            {classData.attachments.length > 0 ? (
              <Button type="primary" onClick={handleAttachments}>
                Anexos
              </Button>
            ) : (
              <Button disabled>Anexos</Button>
            )}

            <div className="comments">
              <span className="title">Comentários</span>
              <CommentArea classId={classData.id} />
              <div className="list">
                {classData?.comments?.map((comment) => (
                  <div className="row">
                    <div className="comment">
                      <div className="content">
                        <span className="authorName">{comment.user.name}</span>
                        <p className="text">{comment.comment}</p>
                        <div className="actions">
                          <span className="date">
                            {moment(comment.updatedAt).format("DD/MM/YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    {comment?.replies?.length > 0 &&
                      comment?.replies.map((reply) => (
                        <div className="answers">
                          <div className="comment">
                            <div className="content">
                              <span className="authorName">
                                {reply.user.name}
                              </span>
                              <p className="text">{reply.comment}</p>
                              <div className="actions">
                                <span className="date">
                                  {moment(reply.updatedAt).format("DD/MM/YYYY")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    <CommentArea
                      classId={classData.id}
                      commentFatherId={comment.id}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col span={6} className="rightSidebar">
            <div className="markAsDone">
              <Checkbox
                onChange={(el) => {
                  return el.target.checked
                    ? dispatch(markClassAsDone(classData.id, el.target.checked))
                    : null;
                }}
                disabled={classData.user_progression.find(
                  (progression) => progression.completed
                )}
                checked={classData.user_progression.find(
                  (progression) => progression.completed
                )}
              >
                Marcar como concluída
              </Checkbox>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Class;
