import { message } from "antd";
import * as service from "../../services/purchase-plan.service";

export const PURCHASE_PLAN__START_LOADING = "PURCHASE_PLAN__START_LOADING";
export const PURCHASE_PLAN__STOP_LOADING = "PURCHASE_PLAN__STOP_LOADING";
export const PURCHASE_PLAN__NEXT_STEP = "PURCHASE_PLAN__NEXT_STEP";
export const PURCHASE_PLAN__PREVIOUS_STEP = "PURCHASE_PLAN__PREVIOUS_STEP";
export const PURCHASE_PLAN__CHANGE_FORM = "PURCHASE_PLAN__CHANGE_FORM";
export const PURCHASE_PLAN__CREATE = "PURCHASE_PLAN__CREATE";
export const PURCHASE_SET_PLAN = "PURCHASE_SET_PLAN";

export function setPlan(plan) {
  return { type: PURCHASE_SET_PLAN, payload: plan };
}

export function startLoading() {
  return { type: PURCHASE_PLAN__START_LOADING };
}

export function stopLoading() {
  return { type: PURCHASE_PLAN__STOP_LOADING };
}

export function nextStep() {
  return { type: PURCHASE_PLAN__NEXT_STEP };
}

export function previousStep() {
  return { type: PURCHASE_PLAN__PREVIOUS_STEP };
}

export function changeForm(formData) {
  return { type: PURCHASE_PLAN__CHANGE_FORM, payload: formData };
}

export function purchasePlan(formData) {
  return function (dispatch) {
    dispatch(startLoading());
    return service
      .purchasePlan(formData)
      .then((resp) => {
        message.success("Plano comprado com sucesso!");
        dispatch(stopLoading());
        dispatch(nextStep());
      })
      .catch((error) => {
        dispatch(stopLoading());
        if (error.response) {
          message.error(error?.response?.data?.error);
        } else {
          message.error(error.message);
        }
      });
  };
}
