import React, { useEffect } from "react";
import { Table, Modal } from "antd";
import moment from "moment";
import { deleteUser, getUsers } from "../../redux/User/User.actions";
import styles from "./UsersGrid.module.scss";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import Header from "../../containers/Header";
import AdmContainer from "../AdmContainer/AdmContainer";
import Footer from "../../containers/Footer";
import ControlPanelMenu from "../ControlPanelMenu/ControlPanelMenu";
import history from "../../utils/history";
import {
  userDetailEmail,
  userDetailName,
} from "../../redux/UserDetailsControlPanel/UserDetailsControlPanel.actions";

const dateFormat = "DD/MM/YYYY";

export default function UsersGrid({ location }) {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.user.list);
  const loading = useSelector((state) => state.user.loading);
  const handleDeleteUser = (userId) => {
    confirm({
      title: "Tem certeza que deseja excluir este usuário?",
      icon: <ExclamationCircleOutlined />,
      content: "Esta ação não poderá ser revertida",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk: () => {
        dispatch(deleteUser(userId));
        Modal.destroyAll();
      },
    });
  };
  const handleGetUser = (record) => {
    dispatch(userDetailEmail(record.email));
    dispatch(userDetailName(record.name));
    history.push(`/control-panel/users/${record.id}`);
  };
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Gênero",
      dataIndex: "gender",
      render: (value) => (value === 1 ? "Masculino" : "Feminino"),
    },
    {
      title: "Data de Nascimento",
      dataIndex: "birthdate",
      render: (value) => moment(value).format(dateFormat),
    },
    {
      title: "É Admin?",
      dataIndex: "isAdmin",
      render: (value) => (value ? "Sim" : "Não"),
    },
    {
      title: "Criado em",
      dataIndex: "created_at",
      render: (value) => moment(value).format(dateFormat),
    },
    {
      title: "Atualizado em",
      dataIndex: "updated_at",
      render: (value) => moment(value).format(dateFormat),
    },
    {
      title: "Ações",
      dataIndex: "id",
      render: (id, record) => (
        <div className="tableActions">
          <button onClick={() => handleGetUser(record)} className="btn">
            Ver
          </button>
          <button onClick={() => handleDeleteUser(id)} className="btn">
            Excluir
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <>
      <Header />
      <AdmContainer>
        <ControlPanelMenu menuActiveKey="users" />
        <div className={styles.UsersGrid}>
          <div className="pageTitle">
            <Breadcrumb title="Usuários" location={location} />
            <h1>Usuários</h1>
          </div>
          <Table dataSource={list} columns={columns} loading={loading} />
        </div>
      </AdmContainer>
      <Footer />
    </>
  );
}
