import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ControlPanelMenu from "../components/ControlPanelMenu/ControlPanelMenu";
import AdmContainer from "../components/AdmContainer/AdmContainer";
import UserProgression from "../components/UserProgression/UserProgression";

export default function UserDetails({ location, match }) {
  const [userId, _] = useState(match.params.id);

  return (
    <>
      <Header />
      <AdmContainer>
        <ControlPanelMenu menuActiveKey="users" />
        <UserProgression location={location} userId={userId} />
      </AdmContainer>
      <Footer />
    </>
  );
}
