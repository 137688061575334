import React from "react";
import { Modal, Input, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import styles from "./ClassConfigModal.module.scss";
import Field from "../Field/Field";
import { useDispatch, useSelector } from "react-redux";
import {
  changeClassForm,
  closeClassModal,
  createClass,
  saveEditedClass,
} from "../../redux/Class/Class.action";

const { Dragger } = Upload;

export default function ClassConfigModal({
  editingMode,
  loading,
  form,
  courseId,
}) {
  const dispatch = useDispatch();
  const classModal = useSelector((state) => state.class.classModal);
  const handleCloseClassModal = () => dispatch(closeClassModal());
  const handleCreateClass = () => {
    if (!form.title) {
      return message.warning("Título é obrigatório");
    }

    if (!form.description) {
      return message.warning("Descrição é obrigatório");
    }

    if (!form.fileListVideo.length) {
      return message.warning("Arquivo de vídeo é obrigatório");
    }

    if (!form.fileListVideo.length > 1) {
      return message.warning("Só é permitido adicionar um vídeo");
    }
    if (!form.fileListAttachment.length) {
      return message.warning("Arquivo de anexo é obrigatório");
    }

    if (!form.fileListAttachment.length > 1) {
      return message.warning("Só é permitido adicionar um anexo");
    }
    dispatch(
      createClass({
        title: form.title,
        description: form.description,
        video: form.fileListVideo[0],
        attachment: form.fileListAttachment[0],
        courseId,
      })
    );
  };

  const handleSaveEditedClass = () => {
    if (!form.title) {
      return message.warning("Título é obrigatório");
    }

    if (!form.description) {
      return message.warning("Descrição é obrigatório");
    }

    if (!form.fileListVideo.length > 1) {
      return message.warning("Só é permitido adicionar um vídeo");
    }

    if (!form.fileListAttachment.length > 1) {
      return message.warning("Só é permitido adicionar um anexo");
    }
    dispatch(
      saveEditedClass({
        id: form.id,
        title: form.title,
        description: form.description,
        video: form.fileListVideo[0] || null,
        attachment: form.fileListAttachment[0] || null,
        courseId,
      })
    );
  };

  const handleChangeClassForm = (value) => {
    dispatch(changeClassForm({ ...form, ...value }));
  };

  const addFileVideo = (file) => {
    handleChangeClassForm({ fileListVideo: [...form.fileListVideo, file] });
    return false;
  };

  const addFileAttachment = (file) => {
    handleChangeClassForm({
      fileListAttachment: [...form.fileListAttachment, file],
    });
    return false;
  };

  const removeFileFromUploadVideo = (file) => {
    const index = form.fileListVideo.findIndex((i) => i.uid === file.uid);

    if (index > -1) {
      handleChangeClassForm({
        fileListVideo: [
          ...form.fileListVideo.slice(0, index),
          ...form.fileListVideo.slice(index + 1),
        ],
      });
    }
  };

  const removeFileFromUploadAttachment = (file) => {
    const index = form.fileListAttachment.findIndex((i) => i.uid === file.uid);

    if (index > -1) {
      handleChangeClassForm({
        fileListAttachment: [
          ...form.fileListAttachment.slice(0, index),
          ...form.fileListAttachment.slice(index + 1),
        ],
      });
    }
  };
  const uploadVideoProps = {
    onRemove: removeFileFromUploadVideo,
    beforeUpload: addFileVideo,
    fileList: classModal.form.fileListVideo,
  };
  const uploadAttachmentProps = {
    onRemove: removeFileFromUploadAttachment,
    beforeUpload: addFileAttachment,
    fileList: classModal.form.fileListAttachment,
  };
  return (
    <Modal
      className={styles.ClassConfigModal}
      visible
      title={editingMode ? "Editar aula" : "Nova aula"}
      loading={loading}
      onCancel={handleCloseClassModal}
      cancelText="Cancelar"
      okText="Salvar"
      onOk={editingMode ? handleSaveEditedClass : handleCreateClass}
      okButtonProps={{
        loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
    >
      <div className="form">
        <Field label="Título" required>
          <Input
            value={form.title}
            onChange={(el) => handleChangeClassForm({ title: el.target.value })}
          />
        </Field>

        <Field label="Descrição" required>
          <Input.TextArea
            value={form.description}
            onChange={(el) =>
              handleChangeClassForm({ description: el.target.value })
            }
          />
        </Field>

        <Field label="Video">
          <Dragger {...uploadVideoProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Clique ou solte arquivos nesta área para fazer upload
            </p>
          </Dragger>
        </Field>
        <Field label="Anexo">
          <Dragger {...uploadAttachmentProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Clique ou solte arquivos nesta área para fazer upload
            </p>
          </Dragger>
        </Field>
      </div>
    </Modal>
  );
}
