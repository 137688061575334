import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import { associateCourseToPlan } from "../../redux/Plans/Plans.action";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../../redux/Courses/Courses.action";
const { Option } = Select;

export default function CPCoursesPlanModal({ closeModal, planDetails }) {
  const dispatch = useDispatch();
  const coursesList = useSelector((state) => state.courses.coursesList);
  const [selectedCourseId, setSelectedCourseId] = useState("");

  useEffect(() => {
    dispatch(getCourses([], true, true));
  }, []);

  const associateCourse = () => {
    dispatch(associateCourseToPlan(planDetails.id, selectedCourseId));
  };

  const onChange = (value) => {
    setSelectedCourseId(value);
  };

  return (
    <Modal
      visible
      onCancel={closeModal}
      title={"Associar curso"}
      okText={"Associar"}
      cancelText="Cancelar"
      onOk={associateCourse}
    >
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder="Selecione um curso"
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {coursesList &&
          coursesList.map((course) => (
            <Option key={course.id} value={course.id}>
              {course.name}
            </Option>
          ))}
      </Select>
    </Modal>
  );
}
