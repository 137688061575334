import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";

import Header from "./Header";
import Footer from "./Footer";
import CourseSidebar from "../components/CourseSidebar/CourseSidebar";
import SiteContainer from "../components/SiteContainer/SiteContainer";
import Class from "../components/Class/Class";
import ClassTest from "../components/ClassTest/ClassTest";
import EClassPageView from "../enums/classPageView";
import { getComments, getCourse } from "../redux/Course/Course.action";

export default function Course({ match }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.course.data);
  const selectedClass = useSelector((state) => state.course.selectedClass);

  useEffect(() => {
    dispatch(getCourse(match.params.id));
  }, []);

  useEffect(() => {
    if (selectedClass && selectedClass.classId) {
      dispatch(getComments(selectedClass.classId));
    }
  }, [selectedClass]);

  return (
    <>
      <Header />
      <SiteContainer>
        <Row>
          <Col span={5}>
            <CourseSidebar />
          </Col>
          <Col span={18}>
            {selectedClass.view === EClassPageView.Class && (
              <Class
                classData={data.classes.find(
                  (c) => c.id === selectedClass.classId
                )}
              />
            )}
            {selectedClass.view === EClassPageView.Test && (
              <ClassTest
                classData={data.classes.find(
                  (c) => c.id === selectedClass.classId
                )}
              />
            )}
          </Col>
        </Row>
      </SiteContainer>
      <Footer />
    </>
  );
}
