import { message } from 'antd'

import * as service from '../../services/courses.service'

export const COURSE_CONFIG__START_LOADING = 'COURSE_CONFIG__START_LOADING'
export const COURSE_CONFIG__STOP_LOADING = 'COURSE_CONFIG__STOP_LOADING'
export const COURSE_CONFIG__OPEN_CLASS_MODAL =
  'COURSE_CONFIG__OPEN_CLASS_MODAL'
export const COURSE_CONFIG__CLOSE_CLASS_MODAL =
  'COURSE_CONFIG__CLOSE_CLASS_MODAL'
export const COURSE_CONFIG__START_CLASS_MODAL_LOADING =
  'COURSE_CONFIG__START_CLASS_MODAL_LOADING'
export const COURSE_CONFIG__STOP_CLASS_MODAL_LOADING =
  'COURSE_CONFIG__STOP_CLASS_MODAL_LOADING'
export const COURSE_CONFIG__CHANGE_CLASS_FORM =
  'COURSE_CONFIG__CHANGE_CLASS_FORM'
export const COURSE_CONFIG__OPEN_QUESTION_MODAL =
  'COURSE_CONFIG__OPEN_QUESTION_MODAL'
export const COURSE_CONFIG__CLOSE_QUESTION_MODAL =
  'COURSE_CONFIG__CLOSE_QUESTION_MODAL'
export const COURSE_CONFIG__START_QUESTION_MODAL_LOADING =
  'COURSE_CONFIG__START_QUESTION_MODAL_LOADING'
export const COURSE_CONFIG__STOP_QUESTION_MODAL_LOADING =
  'COURSE_CONFIG__STOP_QUESTION_MODAL_LOADING'
export const COURSE_CONFIG__CHANGE_QUESTION_FORM =
  'COURSE_CONFIG__CHANGE_QUESTION_FORM'
export const COURSE_CONFIG__SET_CLASSES = 'COURSE_CONFIG__SET_CLASSES'
export const COURSE_CONFIG__REMOVE_CLASS_FROM_LIST =
  'COURSE_CONFIG__REMOVE_CLASS_FROM_LIST'
export const COURSE_CONFIG__ADD_CLASS_FROM_LIST =
  'COURSE_CONFIG__ADD_CLASS_FROM_LIST'
export const COURSE_CONFIG__ADD_CLASS_TO_LIST =
  'COURSE_CONFIG__ADD_CLASS_TO_LIST'
export const COURSE_CONFIG__OPEN_CLASS_MODAL_TO_EDIT =
  'COURSE_CONFIG__OPEN_CLASS_MODAL_TO_EDIT'
export const COURSE_CONFIG__UPDATE_CLASS_FROM_LIST =
  'COURSE_CONFIG__UPDATE_CLASS_FROM_LIST'
export const COURSE_CONFIG__OPEN_QUESTION_MODAL_EDIT =
  'COURSE_CONFIG__OPEN_QUESTION_MODAL_EDIT'

export function startLoading() {
  return { type: COURSE_CONFIG__START_LOADING }
}

export function stopLoading() {
  return { type: COURSE_CONFIG__STOP_LOADING }
}

export function openClassModal() {
  return { type: COURSE_CONFIG__OPEN_CLASS_MODAL }
}

export function closeClassModal() {
  return { type: COURSE_CONFIG__CLOSE_CLASS_MODAL }
}
export function openQuestionModal() {
  return { type: COURSE_CONFIG__OPEN_QUESTION_MODAL }
}

export function openQuestionModalEdit(data) {
  return { type: COURSE_CONFIG__OPEN_QUESTION_MODAL_EDIT, payload: data }
}

export function closeQuestionModal() {
  return { type: COURSE_CONFIG__CLOSE_QUESTION_MODAL }
}

export function startClassModalLoading() {
  return { type: COURSE_CONFIG__START_CLASS_MODAL_LOADING }
}

export function stopClassModalLoading() {
  return { type: COURSE_CONFIG__STOP_CLASS_MODAL_LOADING }
}

export function changeClassForm(form) {
  return {
    type: COURSE_CONFIG__CHANGE_CLASS_FORM,
    payload: form
  }
}
export function startQuestionModalLoading() {
  return { type: COURSE_CONFIG__START_QUESTION_MODAL_LOADING }
}

export function stopQuestionModalLoading() {
  return { type: COURSE_CONFIG__STOP_QUESTION_MODAL_LOADING }
}

export function changeQuestionForm(form) {
  return {
    type: COURSE_CONFIG__CHANGE_QUESTION_FORM,
    payload: form
  }
}

export function setClasses(list) {
  return {
    type: COURSE_CONFIG__SET_CLASSES,
    payload: list
  }
}

export function addClassToList(cls) {
  return {
    type: COURSE_CONFIG__ADD_CLASS_TO_LIST,
    payload: cls
  }
}

export function createClass(form) {
  return function (dispatch) {
    dispatch(startClassModalLoading())
    service
      .createClass(form)
      .then((resp) => {
        dispatch(closeClassModal())
        dispatch(addClassToList(resp.data))
        message.success('Aula criada com sucesso')
      })
      .finally(() => dispatch(stopClassModalLoading()))
  }
}
export function createQuestion(form) {
  return function (dispatch) {
    dispatch(startQuestionModalLoading())
    service
      .createQuestion(form)
      .then((resp) => {
        dispatch(closeQuestionModal())
        message.success('Questão criada com sucesso')
      })
      .finally(() => dispatch(stopQuestionModalLoading()))
  }
}
export function editQuestion(form) {
  return function (dispatch) {
    dispatch(startQuestionModalLoading())
    service
      .editQuestion(form)
      .then((resp) => {
        dispatch(closeQuestionModal())
        message.success('Questão editada com sucesso')
      })
      .finally(() => dispatch(stopQuestionModalLoading()))
  }
}

export function getClasses(courseId) {
  return function (dispatch) {
    dispatch(startLoading())
    service
      .getClasses(courseId)
      .then((resp) => {
        dispatch(setClasses(resp.data))
      })
      .finally(() => dispatch(stopLoading()))
  }
}

export function removeClassFromList(classId) {
  return {
    type: COURSE_CONFIG__REMOVE_CLASS_FROM_LIST,
    payload: classId
  }
}
export function addClassFromList(data) {
  return {
    type: COURSE_CONFIG__ADD_CLASS_FROM_LIST,
    payload: data
  }
}

export function deleteClass(classId) {
  return function (dispatch) {
    dispatch(startLoading())
    service
      .deleteClass(classId)
      .then(() => {
        dispatch(removeClassFromList(classId))
        message.success('Aula excluída com sucesso')
      })
      .finally(() => dispatch(stopLoading()))
  }
}

export function openClassModalToEdit(cls) {
  return {
    type: COURSE_CONFIG__OPEN_CLASS_MODAL_TO_EDIT,
    payload: cls
  }
}

export function updateClassFromList(cls) {
  return {
    type: COURSE_CONFIG__UPDATE_CLASS_FROM_LIST,
    payload: cls
  }
}

export function saveEditedClass(form) {
  return function (dispatch) {
    dispatch(startClassModalLoading())
    service
      .saveEditedClass(form)
      .then(() => {
        dispatch(closeClassModal())
        dispatch(updateClassFromList(form))
        message.success('Aula atualizada com sucesso')
      })
      .finally(() => dispatch(stopClassModalLoading()))
  }
}
