import React from "react";
import { Row, Col, Button, Menu, Dropdown } from "antd";
import { CaretDownOutlined, LoginOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import history from "../../utils/history";
import styles from "./TopBar.module.scss";
import Logo from "../../images/wjschool.png";
import SiteContainer from "../SiteContainer/SiteContainer";

export default function TopBar(props) {
  const { logged, userData, logout, isHome } = props;

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile">Perfil</Link>
      </Menu.Item>
      {userData && userData.user && userData.user.isAdmin ? (
        <Menu.Item>
          <Link to="/control-panel/users">Painel de Controle</Link>
        </Menu.Item>
      ) : (
        <Menu.Item>
          <Link to="/dashboard">Meus cursos</Link>
        </Menu.Item>
      )}
      <Menu.Item onClick={logout}>Sair</Menu.Item>
    </Menu>
  );

  return (
    <div
      className={styles.TopBar}
      style={{
        position: isHome ? "fixed" : "relative",
        backgroundColor: isHome ? "" : "#393939",
        backgroundImage: isHome
          ? "linear-gradient(292deg, #5169ef, #372ce4)"
          : "none",
      }}
    >
      <SiteContainer>
        <Row>
          <Col span={8}>
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            {!logged ? (
              <div className="accessBtns">
                <Button
                  onClick={() => history.push("/login")}
                  icon={<LoginOutlined />}
                  type="link"
                  className="loginBtn"
                >
                  ENTRAR
                </Button>
                <Button
                  onClick={() => history.push("/register")}
                  type="primary"
                >
                  MATRICULE-SE
                </Button>
              </div>
            ) : (
              <Dropdown overlay={menu} placement="bottom">
                <div className="userInfo">
                  <div className="name">
                    {userData.user.name}
                    <CaretDownOutlined className="icon" />
                  </div>
                </div>
              </Dropdown>
            )}
          </Col>
        </Row>
      </SiteContainer>
    </div>
  );
}
