import { httpHelper, headersWithToken } from '../utils/http-helper'

export function getCourses(coursePackageIds, includeTest, listAll, userId) {
  const headers = headersWithToken()

  return httpHelper.get(
    headers,
    {
      coursePackageIds: coursePackageIds
        ? coursePackageIds.toString()
        : undefined,
      includeTest,
      listAll
    },
    'courses'
  )
}

export function getCourse(id) {
  const headers = headersWithToken()
  return httpHelper.get(headers, {}, `courses/${id}`)
}

export function addComment(classId, comment, commentFatherId, packageId, courseId) {
  const headers = headersWithToken()
  const body = {
    "plan_id": packageId,
    "class_id": classId,
    "course_id": courseId,
    "comment": comment
  }

  if (commentFatherId) body["reply_to"] = commentFatherId

  return httpHelper.post(
    headers,
    body,
    `/comments`
  )
}

export function finalizeTest(body) {
  const headers = headersWithToken()
  return httpHelper.post(
    headers,
    body,
    `/answer_question`
  )
}
export function finalizeReTest(body) {
  const headers = headersWithToken()
  return httpHelper.put(
    headers,
    body,
    `/answer_question`
  )
}
export function validateTest(classId) {
  const headers = headersWithToken()
  return httpHelper.get(
    headers,
    {},
    `/answer_question/${classId}`
  )
}
export function getComments(classId) {
  const headers = headersWithToken()
  return httpHelper.get(
    headers,
    {},
    `/comments/${classId}`
  )
}

export function createCourse(form) {
  const headers = headersWithToken()

  return httpHelper.post(
    headers,
    {
      name: form.name,
      description: form.description,
      coursePackageId: form.coursePackageId
    },
    'courses'
  )
}

export function saveCourse(form) {
  const headers = headersWithToken()

  return httpHelper.put(
    headers,
    {
      id: form.id,
      name: form.name,
      description: form.description,
      coursePackageId: 1
    },
    'courses'
  )
}

export function createClass(form) {
  const headers = headersWithToken()
  const { title, description, video, attachment, courseId } = form

  const data = new FormData()
  data.append('title', title)
  data.append('description', description)
  data.append('video', video)
  data.append('attachments', attachment)
  data.append('courseId', courseId)

  return httpHelper.post(headers, data, 'classes')
}
export function createQuestion(form) {
  const headers = headersWithToken()
  return httpHelper.post(headers, form.questions, `/questions/${form.classId}`)
}
export function editQuestion(form) {
  const headers = headersWithToken()
  return httpHelper.put(headers, form.questions, `/questions/updateAllQuestionsClass/${form.classId}`)
}

export function getCourseData(courseId) {
  const headers = headersWithToken()
  return httpHelper.get(headers, {}, `courses/${courseId}`)
}
export function getClasses(courseId) {
  const headers = headersWithToken()
  return httpHelper.get(headers, {}, `classes/${courseId}`)
}

export function getCoursesData() {
  const headers = headersWithToken()
  return httpHelper.get(headers, {}, `courses`)
}

export function deleteClass(classId) {
  const headers = headersWithToken()
  return httpHelper.remove(headers, {}, `classes/${classId}`)
}

export function saveEditedClass(form) {
  const headers = headersWithToken()
  const { title, description, video, attachment, courseId, id } = form

  const data = new FormData()
  data.append('id', id)
  data.append('title', title)
  data.append('description', description)

  if (video) {
    data.append('video', video)
  }

  if (attachment) {
    data.append('attachments', attachment)
  }

  data.append('courseId', courseId)

  return httpHelper.put(headers, data, 'classes')
}

export function deleteCourse(courseId) {
  const headers = headersWithToken()
  return httpHelper.remove(headers, {}, `courses/${courseId}`)
}
