import React from "react";
import { Modal, Input, Row, Col, InputNumber, message } from "antd";
import IntlCurrencyInput from "react-intl-currency-input";

import styles from "./PlanForm.module.scss";
import Field from "../Field/Field";
import { useDispatch } from "react-redux";
import {
  closeModal,
  createPlan,
  saveEditedPlan,
} from "../../redux/Plans/Plans.action";

const { TextArea } = Input;

export const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default function PlanForm({ editMode, form, onChangeForm, loading }) {
  const dispatch = useDispatch();
  const handleCloseModal = () => dispatch(closeModal());
  const savePlan = (id) => {
    const handleSaveEditedPlan = (form) => dispatch(saveEditedPlan(form));
    const handleCreatePlan = (form) => dispatch(createPlan(form));
    if (
      !form.name ||
      !form.description ||
      !form.totalPrice ||
      !form.maxPayTimes
    ) {
      return message.warning("Preencha todos os campos obrigatórios");
    }

    if (id) {
      return handleSaveEditedPlan(form);
    }

    return handleCreatePlan(form);
  };
  return (
    <Modal
      className={styles.PlanForm}
      visible
      title={editMode ? "Editar pacote" : "Criar pacote"}
      okText="Salvar"
      cancelText="Cancelar"
      onCancel={handleCloseModal}
      onOk={editMode ? () => savePlan(form.id) : () => savePlan()}
      loading={loading}
      okButtonProps={{
        disabled: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
    >
      <Field label="Nome" required>
        <Input
          value={form.name}
          onChange={(el) => onChangeForm({ name: el.target.value })}
        />
      </Field>

      <Field label="Descrição" required>
        <TextArea
          rows={2}
          value={form.description}
          onChange={(el) => onChangeForm({ description: el.target.value })}
        />
      </Field>

      <Field label="Tags">
        <Input
          value={form.tags}
          onChange={(el) => onChangeForm({ tags: el.target.value })}
          placeholder="Separado por vírgulas"
        />
      </Field>

      <Row>
        <Col span={12}>
          <Field label="Preço total" required>
            <IntlCurrencyInput
              currency="BRL"
              config={currencyConfig}
              onChange={(event, value) => onChangeForm({ totalPrice: value })}
              value={form.totalPrice}
              className="input"
            />
          </Field>
        </Col>
      </Row>
    </Modal>
  );
}
