import { message } from "antd";

import * as service from "../../services/courses.service";
import EClassPageView from "../../enums/classPageView";
import { setUserProgression } from "../../services/user.service";

export const COURSE__START_LOADING = "COURSE__START_LOADING";
export const COURSE__STOP_LOADING = "COURSE__STOP_LOADING";
export const COURSE__SET_COURSE = "COURSE__SET_COURSE";
export const COURSE__OPEN_CLASS = "COURSE__OPEN_CLASS";
export const COURSE__MARK_CLASS_AS_DONE = "COURSE__MARK_CLASS_AS_DONE";
export const COURSE__CHANGE_CLASS_EVALUATION =
  "COURSE__CHANGE_CLASS_EVALUATION";
export const COURSE__ADD_COMMENT = "COURSE__ADD_COMMENT";
export const COURSE__SET_TEST_USER_TEMPLATE = "COURSE__SET_TEST_USER_TEMPLATE";
export const COURSE__REPEAT_TEST = "COURSE__REPEAT_TEST";
export const COURSE_SET_ANSWERS = "COURSE_SET_ANSWERS";
export const COURSE__SET_COMMENT = "COURSE__SET_COMMENT";
export const COURSE_SET_ANSWERS_PROGRESSION = "COURSE_SET_ANSWERS_PROGRESSION";

export function startLoading() {
  return { type: COURSE__START_LOADING };
}

export function stopLoading() {
  return { type: COURSE__STOP_LOADING };
}

export function setCourse(data) {
  return {
    type: COURSE__SET_COURSE,
    payload: data,
  };
}

export function openClass(classId, view) {
  return {
    type: COURSE__OPEN_CLASS,
    payload: { classId, view },
  };
}

export function getCourse(id) {
  return function (dispatch) {
    dispatch(startLoading());
    return service
      .getCourse(id)
      .then((resp) => {
        dispatch(setCourse(resp.data));
        dispatch(openClass(resp.data.classes[0].id, EClassPageView.Class));
      })
      .catch(() => message.error("Ocorreu um erro"))
      .finally(() => dispatch(stopLoading()));
  };
}

export function markClassAsDone(classId, check) {
  return function (dispatch) {
    dispatch({
      type: COURSE__MARK_CLASS_AS_DONE,
      payload: classId,
    });

    setUserProgression(classId, check)
      .then(() => message.success("Aula marcada como concluída"))
      .catch(() => message.error("Ocorreu um erro"));
  };
}

export function setTestUserTemplate(items, classId) {
  return {
    type: COURSE__SET_TEST_USER_TEMPLATE,
    payload: { items, classId },
  };
}

export function finalizeTest(classId, packageId, answers, repeatTest) {
  const finalizeService = repeatTest ? "finalizeReTest" : "finalizeTest";
  const body = {
    answers,
    packageId,
    classId,
  };

  return function (dispatch) {
    return service[finalizeService](body)
      .then(() => {
        message.success("Avaliação concluída com sucesso");
        dispatch(validateTest(classId));
        dispatch(setAnswersProgression(classId, true));
        if (repeatTest) dispatch(setRepeatTest(false));
      })
      .catch(() => message.error("Ocorreu um erro"));
  };
}

export function validateTest(classId) {
  return function (dispatch) {
    service
      .validateTest(classId)
      .then((response) => {
        dispatch(setAnswers(response.data));
      })
      .catch(() => message.error("Ocorreu um erro"));
  };
}

export function getComments(classId) {
  return function (dispatch) {
    service
      .getComments(classId)
      .then((response) => {
        dispatch({
          type: COURSE__SET_COMMENT,
          payload: { classId, comments: response.data },
        });
      })
      .catch(() => message.error("Ocorreu um erro"));
  };
}

export function addComment(
  classId,
  comment,
  commentFatherId,
  packageId,
  courseId,
  userName
) {
  return function (dispatch) {
    service
      .addComment(classId, comment, commentFatherId, packageId, courseId)
      .then((response) =>
        dispatch({
          type: COURSE__ADD_COMMENT,
          payload: { ...response.data, user: { name: userName } },
        })
      )
      .catch(() => message.error("Ocorreu um erro"));
  };
}

export function setAnswers(obj) {
  return {
    type: COURSE_SET_ANSWERS,
    payload: obj,
  };
}
export function setAnswersProgression(classId, answered) {
  return {
    type: COURSE_SET_ANSWERS_PROGRESSION,
    payload: { classId, answered },
  };
}
export function setRepeatTest(value) {
  return {
    type: COURSE__REPEAT_TEST,
    payload: value,
  };
}
export function repeatTest(classId) {
  return {
    type: COURSE__REPEAT_TEST,
    payload: classId,
  };
}
