import React from "react";
import styles from "./DashboardCourses.module.scss";
import SiteContainer from "../SiteContainer/SiteContainer";
import { Col } from "antd";
import CourseCard from "../CourseCard/CourseCard";

function DashboardCourses({ coursesPackages }) {
  return (
    <SiteContainer>
      <div className={styles.DashboardCourses}>
        <div className="title">Meus Cursos</div>

        <Col className="list">
          {coursesPackages.length > 0 ? (
            coursesPackages.map((coursesPackage) => {
              const packages = coursesPackage.course_package;
              const courseshtml = packages.courses.map((course) => (
                <CourseCard key={course.id} course={course} />
              ));
              return (
                <Col>
                  <strong className="packageName">{packages.name}</strong>
                  <div className="packageList">{courseshtml}</div>
                </Col>
              );
            })
          ) : (
            <strong className="noCourse">
              Não existe cursos para sua conta
            </strong>
          )}
        </Col>
      </div>
    </SiteContainer>
  );
}

export default DashboardCourses;
