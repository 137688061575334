import React from 'react'
import { connect } from 'react-redux'
import { message } from 'antd'

import LoginPage from '../components/LoginPage/LoginPage'
import { login } from '../redux/Session/Session.action'
import Header from './Header'
import Footer from './Footer'
import SiteContainer from '../components/SiteContainer/SiteContainer'

class Login extends React.Component {
  state = {
    form: {
      email: '',
      password: ''
    }
  };

  logIn = () => {
    const { handleLogin } = this.props
    const { form } = this.state

    if (!form.email) {
      return message.warning('Digite seu nome de usuário')
    }

    if (!form.password) {
      return message.warning('Digite sua senha')
    }

    return handleLogin(form.email, form.password)
  }

  changeForm = (value) => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        ...value
      }
    })
  }

  render () {
    const { loginLoad } = this.props

    return (
      <>
        <Header />
        <SiteContainer>
          <LoginPage
            changeForm={this.changeForm}
            loading={loginLoad}
            logIn={this.logIn}
          />
        </SiteContainer>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = state => ({
  loginLoad: state.session.loginLoad
})

const mapDispatchToProps = dispatch => ({
  handleLogin: (email, password) => dispatch(login(email, password))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
