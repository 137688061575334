import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import { FolderAddOutlined, FolderOutlined } from "@ant-design/icons";
import classNames from "classnames";

import styles from "./CourseSidebar.module.scss";
import EClassPageView from "../../enums/classPageView";
import { useDispatch, useSelector } from "react-redux";
import { openClass, validateTest } from "../../redux/Course/Course.action";

export default function CourseSidebar() {
  const dispatch = useDispatch();
  const [activeTabs, setActiveTabs] = useState([]);
  const data = useSelector((state) => state.course.data);
  const selectedClass = useSelector((state) => state.course.selectedClass);

  const openTab = (id) => {
    setActiveTabs([...activeTabs, id]);
  };

  const closeTab = (id) => {
    setActiveTabs(activeTabs.filter((item) => item !== id));
  };

  useEffect(() => {
    setActiveTabs([...activeTabs, 0]);
  }, []);

  const getCourseProgress = () => {
    const totalClasses = data.classes.length;
    const totalDone = data.classes.filter((el) =>
      el.user_progression.find((progression) => progression.completed)
    ).length;

    const total = ((totalDone / totalClasses) * 100).toFixed();
    return (
      <Progress type="circle" percent={total} className="progress" width={50} />
    );
  };

  return (
    <div className={styles.CourseSidebar}>
      <div className="header">
        {getCourseProgress()}
        <div className="text">
          <span className="title">{data.name}</span>
        </div>
      </div>
      <div className="list">
        {data.classes.map((item, index) => (
          <div className="item" key={index}>
            <div className="name">
              {activeTabs.includes(index) ? (
                <button onClick={() => closeTab(index)} className="close">
                  <FolderOutlined />
                </button>
              ) : (
                <button onClick={() => openTab(index)}>
                  <FolderAddOutlined />
                </button>
              )}

              <span
                className="title"
                onClick={
                  activeTabs.includes(index)
                    ? () => closeTab(index)
                    : () => openTab(index)
                }
              >
                {item.title}
              </span>
            </div>
            {activeTabs.includes(index) ? (
              <ul className="options">
                <li
                  className={classNames("", {
                    done: item.user_progression[0]?.completed,
                    active:
                      selectedClass.classId === item.id &&
                      selectedClass.view === EClassPageView.Class,
                  })}
                  onClick={() =>
                    dispatch(openClass(item.id, EClassPageView.Class))
                  }
                >
                  Aula
                </li>
                {item.questions && item.questions.length > 0 ? (
                  <li
                    className={classNames("", {
                      done: item.user_progression[0]?.answered,
                      active:
                        selectedClass.classId === item.id &&
                        selectedClass.view === EClassPageView.Test,
                    })}
                    onClick={() =>
                      dispatch(openClass(item.id, EClassPageView.Test))
                    }
                  >
                    Avaliação
                  </li>
                ) : (
                  ""
                )}
              </ul>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
