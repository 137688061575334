import Immutable from 'seamless-immutable'

import * as actions from './ControlPanel.actions'

const initialState = () =>
  Immutable({
    loading: false,
    coursesPlanModalData: {
      show: false,
      loading: false
    }
  })

export default function controlPanelReducer(state = initialState(), action) {
  switch (action.type) {
    case actions.CONTROL_PANEL_OPEN_COURSES_PLAN_MODAL:
      return state.setIn(['coursesPlanModalData', 'show'], true)
    case actions.CONTROL_PANEL_CLOSE_COURSES_PLAN_MODAL:
      return state.set(
        'coursesPlanModalData',
        initialState().coursesPlanModalData
      )
    case actions.CONTROL_PANEL_START_LOADING:
      return state.set('loading', true)
    case actions.CONTROL_PANEL_STOP_LOADING:
      return state.set('loading', false)
    default:
      return state
  }
}
