import Immutable from 'seamless-immutable'

import * as actions from './Course.action'

const initialState = () =>
  Immutable({
    loading: false,
    data: {
      classes: []
    },
    answers: [],
    repeatTest: false,
    selectedClass: {
      classId: null,
      view: null
    }
  })

export default function courseReducer(state = initialState(), action) {
  switch (action.type) {
    case actions.COURSE__START_LOADING:
      return state.set('loading', true)
    case actions.COURSE__STOP_LOADING:
      return state.set('loading', false)
    case actions.COURSE__SET_COURSE:
      return state.set('data', action.payload)
    case actions.COURSE_SET_ANSWERS:
      return state.setIn(['answers'], action.payload)
    case actions.COURSE__OPEN_CLASS:
      return state.set('selectedClass', {
        classId: action.payload.classId,
        view: action.payload.view
      })
    case actions.COURSE__MARK_CLASS_AS_DONE: {
      const list = state.data.classes.map((item) => {
        if (item.id !== action.payload) return item
        return {
          ...item,
          user_progression: item.user_progression.map(progression => {
            return {
              ...progression,
              completed: true
            }
          })
        }
      })
      return state.setIn(['data', 'classes'], list)
    }
    case actions.COURSE__CHANGE_CLASS_EVALUATION: {
      const list = state.data.classes.map((item) => {
        if (item.id !== action.payload.classId) return item
        return { ...item, evaluation: action.payload.evaluation }
      })
      return state.setIn(['data', 'classes'], list)
    }
    case actions.COURSE__SET_TEST_USER_TEMPLATE: {
      const list = state.data.classes.map((item) => {
        if (item.id !== action.payload.classId) return item
        return {
          ...item,
          test: {
            ...item.test,
            userTemplate: action.payload.items
          }
        }
      })
      return state.setIn(['data', 'classes'], list)
    }
    case actions.COURSE__REPEAT_TEST: {

      return state.setIn(['repeatTest'], action.payload)
    }
    case actions.COURSE__ADD_COMMENT: {
      const { class_id, reply_to } = action.payload;
      return state.setIn(['data', 'classes'], state.data.classes.map(item => {
        if (item.id !== class_id) return item;

        if (reply_to) {
          return {
            ...item,
            comments: item.comments.map(comment => {
              if (comment.id !== reply_to) return comment;
              return {
                ...comment,
                replies: [...comment.replies, action.payload]
              }
            })
          }
        }
        return {
          ...item,
          comments: [...item.comments, action.payload]
        }
      }))
    }
    case actions.COURSE__SET_COMMENT: {
      const { classId, comments } = action.payload;
      return state.setIn(['data', 'classes'], state.data.classes.map(item => {
        if (item.id !== classId) return item;
        return {
          ...item,
          comments
        }
      }))
    }
    case actions.COURSE_SET_ANSWERS_PROGRESSION: {
      const { classId, answered } = action.payload;
      state.setIn(['repeatTest'], false);
      return state.setIn(['data', 'classes'], state.data.classes.map(item => {
        if (item.id !== classId) return item;
        return {
          ...item,
          user_progression: item.user_progression.map(obj => { return { ...obj, answered } })
        }
      }))
    }
    default:
      return state
  }
}
