import React from 'react'
import {
  Input,
  Radio,
  Button,
  DatePicker
} from 'antd'
import moment from 'moment'
import Icon from '@ant-design/icons'
import { Link } from 'react-router-dom'

import styles from './RegisterForm.module.scss'
import SiteContainer from '../SiteContainer/SiteContainer'

class RegisterForm extends React.PureComponent {
  render () {
    const { form, onChangeForm, loading, onSubmitForm } = this.props

    return (
      <div className={styles.RegisterForm}>
        <SiteContainer>
          <form className="form">
            <strong className="title">Inscrever-se</strong>
            <div className="item">
              <span className="label">Seu nome</span>
              <Input
                placeholder="Digite seu nome completo"
                style={{ width: '100%' }}
                size="large"
                value={form.name}
                required
                onChange={el => onChangeForm({ name: el.target.value })}
              />
            </div>
            <div className="item">
              <span className="label">Seu e-mail</span>
              <Input
                placeholder="Digite seu e-mail"
                style={{ width: '100%' }}
                size="large"
                value={form.email}
                type="email"
                required
                onChange={el => onChangeForm({ email: el.target.value })}
              />
            </div>
            <div className="item">
              <span className="label">Sua senha (//min 6)</span>
              <Input
                type="password"
                placeholder="Digite sua senha"
                style={{ width: '100%' }}
                size="large"
                value={form.password}
                required
                minLength={6}
                onChange={el => onChangeForm({ password: el.target.value })}
              />
            </div>
            {/* <div className="item">
              <span className="label">Seu apelido</span>
              <Input
                placeholder="Como devemos chamar você?"
                style={{ width: '100%' }}
                size="large"
                value={form.nickname}
                onChange={el => onChangeForm({ nickname: el.target.value })}
              />
            </div> */}
            <div className="item birthdate">
              <span className="label">Data de nascimento</span>
              <DatePicker
                format={'DD/MM/YYYY'}
                onChange={(value) => onChangeForm({ birthdate: value.format() })}
                style={{ width: '100%' }}
                disabledDate={current => moment() <= current}
                placeholder="Digite sua data de nascimento"
              />
            </div>

            <div className="item">
              <span className="label">Sexo</span>
              <Radio.Group
                value={form.gender}
                onChange={el => onChangeForm({ gender: el.target.value })}
              >
                <Radio value={1}>Masculino</Radio>
                <Radio value={2}>Feminino</Radio>
              </Radio.Group>
            </div>

            {/* <div className="item">
              <Checkbox
                style={{ marginRight: 10 }}
                value={form.agreeTerms}
                onChange={el => onChangeForm({ agreeTerms: el.target.checked })}
              ></Checkbox>
              Eu aceito os <a href="#">Termos e condições e a Política de privacidade</a> do WJ School
            </div> */}

            <div className="item center">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="btn"
                loading={loading}
                onClick={(e) => onSubmitForm(e)}
              >
                Inscrever-se
                <Icon type="arrow-right" />
              </Button>
            </div>

            <div className="item center">
              <span>Já tem uma conta? </span>
              <Link to="/login">Entrar</Link>
            </div>
          </form>
        </SiteContainer>
      </div>
    )
  }
}

export default RegisterForm
