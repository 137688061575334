import { httpHelper, headersWithToken } from '../utils/http-helper'

export function getPlans() {
  const headers = headersWithToken()
  return httpHelper.get(headers, {}, 'course_packages')
}

export function createPlan(form) {
  const headers = headersWithToken()
  const params = {
    name: form.name,
    description: form.description,
    fullPrice: form.totalPrice * 100,
    maxPayTimes: form.maxPayTimes,
    tags: form.tags.length ? form.tags.trim().split(',') : []
  }
  return httpHelper.post(headers, params, 'course_packages')
}

export function deletePlan(planId) {
  const headers = headersWithToken()
  return httpHelper.remove(headers, {}, `course_packages/${planId}`)
}

export function saveEditedPlan(form) {
  const headers = headersWithToken()
  const params = {
    id: form.id,
    name: form.name,
    description: form.description,
    fullPrice: form.totalPrice * 100,
    maxPayTimes: form.maxPayTimes,
    tags: form.tags.length ? form.tags.trim().split(',') : []
  }
  return httpHelper.put(headers, params, 'course_packages')
}

export function getPlan(planId) {
  const headers = headersWithToken()
  return httpHelper.get(headers, {}, `course_packages/${planId}`)
}
export function getPlanCoursesClasses(userId, courseId) {
  const headers = headersWithToken()
  return httpHelper.get(headers, {}, `/classes/${courseId}/${userId}`)
}

export function associateCourseToPlan(planId, courseId) {
  const headers = headersWithToken()
  const params = {
    courseId,
    planId
  }
  return httpHelper.put(headers, params, `/course_packages/courses`)
}
