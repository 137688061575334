import { combineReducers } from 'redux'

import SessionReducer from './Session/Session.reducer'
import PlansReducer from './Plans/Plans.reducer'
import CoursesReducer from './Courses/Courses.reducer'
import CourseReducer from './Course/Course.reducer'
import PurchasePlanReducer from './PurchasePlan/PurchasePlan.reducer'
import ControlPanelReducer from './ControlPanel/ControlPanel.reducer'
import ClassReducer from './Class/Class.reducer'
import UserReducer from './User/User.reducer'
import UserDetailsControlPanel from './UserDetailsControlPanel/UserDetailsControlPanel.reducer'

const rootReducer = combineReducers({
  session: SessionReducer,
  plans: PlansReducer,
  courses: CoursesReducer,
  course: CourseReducer,
  purchasePlan: PurchasePlanReducer,
  controlPanel: ControlPanelReducer,
  class: ClassReducer,
  user: UserReducer,
  userDetails: UserDetailsControlPanel
})

export default rootReducer
