import React from "react";
import { useSelector } from "react-redux";

import styles from "./UserInfoAdmin.module.scss";

const UserInfoAdmin = () => {
  const { name, email } = useSelector((state) => state.userDetails);
  return (
    <div className={styles.UserInfoAdmin}>
      <span>
        {name} | {email}
      </span>
    </div>
  );
};

export default UserInfoAdmin;
