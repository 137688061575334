import { httpHelper, headersWithToken } from "../utils/http-helper";

export function purchasePlan(form) {
  try {
    const [month, year] = form.creditCardExpireDate.split("/");
    const {
      name,
      cpf,
      email,
      paymentAddress,
      number,
      district,
      city,
      state,
      cep,
      complement,
      planId,
    } = form;
    const { encryptedCard, errors, hasErrors } = window.PagSeguro.encryptCard({
      publicKey: process.env.REACT_APP_PAGSEGURO_PUBLIC_KEY,
      holder: form.creditCardName,
      number: form.creditCardNumber.replace(/ /g, ""),
      expMonth: month,
      expYear: year,
      securityCode: form.creditCardCVV,
    });
    if (hasErrors) {
      throw new Error(errorHelper(errors[0].code));
    }
    const headers = headersWithToken();
    const params = {
      name,
      cpf,
      email,
      paymentAddress,
      number,
      district,
      city,
      state,
      cep,
      complement,
      encryptedCreditCard: encryptedCard,
      planId,
    };
    return httpHelper.post(headers, params, "checkout");
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
}

function errorHelper(errorCode) {
  switch (errorCode) {
    case "INVALID_NUMBER":
      return "Número de cartão inválido.";
    case "INVALID_SECURITY_CODE":
      return "CVV inválido.";
    case "INVALID_EXPIRATION_MONTH":
      return "Vencimento inválido.";
    case "INVALID_EXPIRATION_YEAR":
      return "Vencimento inválido.";
    case "INVALID_PUBLIC_KEY":
      return "Chave de api inválida.";
    case "INVALID_HOLDER":
      return "Nome do Titular inválido.";
    default:
      break;
  }
}
