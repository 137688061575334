import React from 'react'
import { Col, Button, Input } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import styles from './LoginPage.module.scss'
import loginImage from '../../images/login.png'
import { Link } from 'react-router-dom'

class LoginPage extends React.PureComponent {
  render () {
    const { loading, changeForm, logIn } = this.props

    return (
      <div className={styles.LoginPage}>
        <Col span={16}>
          <img src={loginImage} alt="" />
        </Col>

        <Col span={8}>
          <div className="box">
            <span className="title">Seja bem-vindo</span>
            <span className="subtitle">Faça login para continuar</span>
            <div className="form">
              <div>
                <span className="label required">E-mail</span>
                <Input
                  placeholder="E-mail"
                  prefix={<UserOutlined />}
                  onChange={el => changeForm({ email: el.target.value })}
                  className="field"
                />
              </div>

              <div>
                <span className="label required">Senha</span>
                <Input
                  placeholder="Senha"
                  onChange={el => changeForm({ password: el.target.value })}
                  onPressEnter={logIn}
                  type="password"
                  className="field"
                />
              </div>

              <div>
                <Button
                  type="primary"
                  className="btn"
                  onClick={logIn}
                  loading={loading}
                >
                  Entrar
                </Button>
                <Link className="register" to="/register">Ou crie uma nova conta!</Link>
              </div>
            </div>

            {/* <Row>
              <Link to="/">Voltar para o site</Link>
            </Row> */}
          </div>
        </Col>
      </div>
    )
  }
}

export default LoginPage
