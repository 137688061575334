import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { PurchasePlanContainer } from "../components/PurchasePlanContainer/PurchasePlanContainer";

export default function PurchasePlan({ match }) {
  return (
    <>
      <Header fromHome />
      <PurchasePlanContainer match={match} />
      <Footer />
    </>
  );
}
