import React from "react";
import { Input, Select } from "antd";
const { Option } = Select;
import Field from "../Field/Field";

export default function Question({ questionNumber = 1, section, setSection }) {
  return (
    <div className="form">
      <Field label={"Pergunta " + questionNumber} marginBottom={10} required>
        <Input
          value={section.title}
          onChange={(value) =>
            setSection({ ...section, title: value.target.value })
          }
        />
      </Field>

      <div className="options">
        <Field label="Opção 1" marginBottom={5} required>
          <Input
            value={section.option_1}
            onChange={(value) =>
              setSection({ ...section, option_1: value.target.value })
            }
          />
        </Field>
        <Field label="Opção 2" marginBottom={5} required>
          <Input
            value={section.option_2}
            onChange={(value) =>
              setSection({ ...section, option_2: value.target.value })
            }
          />
        </Field>
        <Field label="Opção 3" marginBottom={5} required>
          <Input
            value={section.option_3}
            onChange={(value) =>
              setSection({ ...section, option_3: value.target.value })
            }
          />
        </Field>
        <Field label="Opção 4" marginBottom={5} required>
          <Input
            value={section.option_4}
            onChange={(value) =>
              setSection({ ...section, option_4: value.target.value })
            }
          />
        </Field>
        <Field label="Opção correta" required>
          <Select
            style={{ width: "100%" }}
            defaultValue={section.answer_option}
            onChange={(value) =>
              setSection({ ...section, answer_option: value })
            }
          >
            <Option value="option_1">Opção 1</Option>
            <Option value="option_2">Opção 2</Option>
            <Option value="option_3">Opção 3</Option>
            <Option value="option_4">Opção 4</Option>
          </Select>
        </Field>
      </div>
    </div>
  );
}
