import Immutable from 'seamless-immutable'

import * as actions from './Class.action'

const formDefault = {
  id: null,
  title: '',
  description: '',
  fileListVideo: [],
  fileListAttachment: []
}

const initialState = () =>
  Immutable({
    courseData: {
      name: '',
      classes: []
    },
    loading: false,
    classes: [],
    classModal: {
      show: false,
      form: formDefault,
      loading: false,
      editingMode: false
    },
    questionModal: {
      show: false,
      form: { classId: '', questions: [] },
      isEdit: false,
      data: [{}, {}, {}, {}, {}],
      loading: false,
    }
  })

export default function ClassReducer(state = initialState(), action) {
  switch (action.type) {
    case actions.COURSE_CONFIG__START_LOADING:
      return state.set('loading', true)
    case actions.COURSE_CONFIG__STOP_LOADING:
      return state.set('loading', false)
    case actions.COURSE_CONFIG__OPEN_CLASS_MODAL:
      return state.setIn(['classModal', 'show'], true)
    case actions.COURSE_CONFIG__CLOSE_CLASS_MODAL:
      return state.set('classModal', initialState().classModal)
    case actions.COURSE_CONFIG__START_CLASS_MODAL_LOADING:
      return state.setIn(['classModal', 'loading'], true)
    case actions.COURSE_CONFIG__STOP_CLASS_MODAL_LOADING:
      return state.setIn(['classModal', 'loading'], false)
    case actions.COURSE_CONFIG__CHANGE_CLASS_FORM:
      return state.setIn(['classModal', 'form'], action.payload)
    case actions.COURSE_CONFIG__OPEN_QUESTION_MODAL:
      return state.setIn(['questionModal', 'show'], true)
    case actions.COURSE_CONFIG__CLOSE_QUESTION_MODAL:
      return state.set('questionModal', initialState().questionModal)
    case actions.COURSE_CONFIG__START_QUESTION_MODAL_LOADING:
      return state.setIn(['questionModal', 'loading'], true)
    case actions.COURSE_CONFIG__OPEN_QUESTION_MODAL_EDIT:
      return state.setIn(['questionModal'], {
        ...state.questionModal,
        show: true,
        isEdit: true,
        data: action.payload
      })
    case actions.COURSE_CONFIG__STOP_QUESTION_MODAL_LOADING:
      return state.setIn(['questionModal', 'loading'], false)
    case actions.COURSE_CONFIG__CHANGE_QUESTION_FORM:
      return state.setIn(['questionModal', 'form'], action.payload)
    case actions.COURSE_CONFIG__SET_CLASSES:
      return state.set('classes', action.payload)
    case actions.COURSE_CONFIG__REMOVE_CLASS_FROM_LIST:
      return state.setIn(
        ['classes'],
        state.classes.filter((cls) => cls.id !== action.payload)
      )
    case actions.COURSE_CONFIG__ADD_CLASS_TO_LIST:
      return state.setIn(
        ['classes'],
        [...state.classes, action.payload]
      )
    case actions.COURSE_CONFIG__OPEN_CLASS_MODAL_TO_EDIT:
      return state
        .setIn(['classModal', 'show'], true)
        .setIn(['classModal', 'form'], {
          ...state.classModal.form,
          ...action.payload
        })
        .setIn(['classModal', 'editingMode'], true)
    case actions.COURSE_CONFIG__UPDATE_CLASS_FROM_LIST:
      return state.setIn(
        ['courseData', 'classes'],
        state.courseData.classes.map((item) => {
          if (item.id !== action.payload.id) return item
          return action.payload
        })
      )
    default:
      return state
  }
}
