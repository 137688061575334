import { headersWithToken, httpHelper } from '../utils/http-helper'

export function registerUser({
  name,
  email,
  password,
  birthdate,
  gender
}) {
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }

  return httpHelper.post(
    headers,
    {
      name,
      email,
      password,
      birthdate,
      gender
    },
    'users'
  )
}
export function getUserPlans() {
  const headers = headersWithToken()

  return httpHelper.get(
    headers,
    {},
    'user_plans'
  )
}
export function getOneUserPlans(userId) {
  const headers = headersWithToken()

  return httpHelper.get(
    headers,
    {},
    `user_plans/${userId}`
  )
}

export function setUserProgression(classId, isCompleat) {
  const headers = headersWithToken()

  return httpHelper.post(
    headers,
    {
      classId: classId,
      isCompleat: isCompleat
    },
    'user_progression'
  )
}

export function updateUser({
  name,
  email,
  birthdate,
  gender,
  oldPassword,
  password,
  confirmPassword
}) {
  let body = {
    name,
    email,
    birthdate,
    gender
  }
  if (oldPassword) {
    body = {
      ...body,
      oldPassword,
      password,
      confirmPassword
    }
  }
  return httpHelper.put(
    {},
    body,
    'users'
  )
}

export function getUsers() {
  const headers = headersWithToken()
  return httpHelper.get(headers, {}, 'users')
}

export function deleteUser(userId) {
  const headers = headersWithToken()
  return httpHelper.remove(headers, {}, `users/${userId}`)
}

export function activateUser(userId) {
  const headers = headersWithToken()
  return httpHelper.patch(headers, `users/activate/${userId}`)
}

export function deactivateUser(userId) {
  const headers = headersWithToken()
  return httpHelper.patch(headers, `users/deactivate/${userId}`)
}
