import React from "react";
import Img from "../../images/example3.png";
import history from "../../utils/history";
import styles from "./CourseCard.module.scss";

function CourseCard({ course, isHome }) {
  return (
    <div
      className={styles.CourseCard}
      onClick={() => {
        if (!isHome) {
          history.push(`/course/${course.id}`);
        }
      }}
      style={isHome ? { cursor: "default" } : {}}
    >
      <img src={Img} alt="Logo" />
      <div className="infoContainer">
        <strong className="name">{course.name}</strong>
        <div className="description">{course.description}</div>
        <div className="infoGroup">
          <div className="infoGroup">
            <span>{course.classesCount}</span>
            <span className="material-symbols-rounded">school</span>
          </div>
          <span> | </span>
          <div className="infoGroup">
            <span>{(course.classesDuration / 60).toFixed(2)} h</span>
            <span className="material-symbols-rounded">schedule</span>
          </div>
          <span> | </span>
          <div className="infoGroup">
            <span>{course.classesAttachments} </span>
            <span className="material-symbols-rounded">attach_file</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
