import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  Rate
} from 'antd'
import Icon from '@ant-design/icons'

import styles from './HomeBanner.module.scss'
import bannerImg from '../../images/bannerimg.png'
import SiteContainer from '../SiteContainer/SiteContainer'
import imgExample1 from '../../images/example1.png'
import imgExample2 from '../../images/example2.png'
import imgExample3 from '../../images/example3.png'

export default function HomeBanner (props) {
  return (
    <div className={styles.HomeBanner}>
      <SiteContainer>
        <Row className="bannerContent">
          <Col span={12}>
            <h2 className="title">A MANEIRA MAIS RÁPIDA E PRÁTICA DE APRENDER INGLÊS ONLINE</h2>
            <h3 className="subtitle">
            Curso de inglês online e metodologia descomplicada. Estude inglês todos os dias no conforto da sua casa.
            </h3>
            <Button
              type="primary"
              size="large"
              className="cta"
            >
              Começar agora
              <Icon type="arrow-right" />
            </Button>
            <span className="link">
              Tem cadastro na WJ School? <Link to="/login">Acesse agora o seu perfil.</Link>
            </span>
          </Col>

          <Col span={12}>
            <img src={bannerImg} alt="" className="bannerImg" />
          </Col>
        </Row>
        <Row className="testimonials">
          <Col span={8}>
            <div className="item">
              <div className="image">
                <img src={imgExample1} alt=""/>
              </div>
              <div className="content">
                <span className="title">Iniciante</span>
                <p className="text">Curso com ênfase na conversação e composto por aulas dinâmicas e interativas.</p>
                <Rate disabled defaultValue={5} />
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="item">
              <div className="image">
                <img src={imgExample2} alt=""/>
              </div>
              <div className="content">
                <span className="title">Intermediário</span>
                <p className="text">O aluno aprende a compreender, falar, ler e escrever com fluência e correção em contextos formais.</p>
                <Rate disabled defaultValue={5} />
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="item">
              <div className="image">
                <img src={imgExample3} alt=""/>
              </div>
              <div className="content">
                <span className="title">Avançado</span>
                <p className="text">Curso com ênfase na conversação e composto por aulas dinâmicas e interativas que ensinam.</p>
                <Rate disabled defaultValue={5} />
              </div>
            </div>
          </Col>
        </Row>
      </SiteContainer>
    </div>
  )
}
