import React, { useEffect, useState } from "react";

import ClassesConfigList from "../components/ClassesConfigList/ClassesConfigList";
import Header from "./Header";
import Footer from "./Footer";
import { getClasses } from "../redux/Class/Class.action";
import ControlPanelMenu from "../components/ControlPanelMenu/ControlPanelMenu";
import AdmContainer from "../components/AdmContainer/AdmContainer";
import { useDispatch } from "react-redux";

export default function CourseConfig({ location, match }) {
  const dispatch = useDispatch();
  const handleGetClassData = (courseId) => dispatch(getClasses(courseId));

  const [courseId, _] = useState(match.params.id);

  useEffect(() => {
    handleGetClassData(courseId);
  }, []);

  return (
    <>
      <Header />
      <AdmContainer>
        <ControlPanelMenu menuActiveKey="courses" />
        <ClassesConfigList location={location} courseId={courseId} />
      </AdmContainer>
      <Footer />
    </>
  );
}
