import React, { useState } from "react";
import styles from "./CourseProgression.module.scss";
import { Col, message, Table } from "antd";
import { useEffect } from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { getPlanCoursesClasses } from "../../services/plans.service";
import UserInfoAdmin from "../UserInfoAdmin/UserInfoAdmin";

function CourseProgression({ location, userId, course }) {
  const [courseData, setCourseData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getPlanCoursesClasses(userId, course.id)
      .then((response) => {
        setCourseData(response.data);
      })
      .catch((err) => {
        message.error("Erro ao buscar planos do usuário.");
      })
      .finally(() => setLoading(false));
  }, [userId, course]);
  const columns = [
    {
      title: "Aula",
      dataIndex: "title",
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Duração",
      dataIndex: "duration",
      render: (value, row) => Math.floor(value) + " m",
    },
    {
      title: "Completa",
      dataIndex: "user_progression",
      render: (value, row) => (value[0].completed ? "Sim" : "Não"),
    },
    {
      title: "Questionário",
      dataIndex: "user_progression",
      render: (value, row) =>
        value[0].answered ? "Respondido" : "Não respondido",
    },
  ];

  return (
    <div className={styles.CourseProgression}>
      <div className="info-header">
        <Breadcrumb location={location} />
        <UserInfoAdmin />
      </div>
      <div className="title">Cursos</div>

      <Col className="list">
        {courseData?.aulas?.length > 0 ? (
          <Col style={{ marginBottom: 20 }}>
            <strong className="courseName">{course.name}</strong>
            <span className="courseDescription">{course.description}</span>
            <div className="infoGerais">
              <span>
                <strong>Total de aulas:</strong>{" "}
                {courseData.user_aulas.total_aulas}
              </span>
              <span>
                <strong>Total de aulas completadas:</strong>{" "}
                {courseData.user_aulas.total_aulas_completadas}
              </span>
              <span>
                <strong>Aulas respondidas:</strong>{" "}
                {courseData.qtd_aulas_respondidas}
              </span>
              <span>
                <strong>Aulas respondidas corretamente:</strong>{" "}
                {courseData.qtd_aulas_respondidas}
              </span>
              <span>
                <strong>Total de questoes do curso:</strong>{" "}
                {courseData.user_questions.total_de_questoes_do_curso}
              </span>
              <span>
                <strong>Questoes respondidas pelo usuário:</strong>{" "}
                {
                  courseData.user_questions
                    .total_de_questoes_respondidas_pelo_usuario
                }
              </span>
            </div>
            <Table
              columns={columns}
              dataSource={courseData?.aulas}
              className="classesGrid"
              loading={loading}
            />
          </Col>
        ) : (
          <strong className="noCourse">
            Não existe aulas para esse curso.
          </strong>
        )}
      </Col>
    </div>
  );
}

export default CourseProgression;
