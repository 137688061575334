import { message } from "antd";

import * as service from "../../services/user.service";
import { getCourses } from "../../services/courses.service";
import { history } from "../../utils/history";
import { setSession } from "../Session/Session.action";
import { getSession } from "../../utils/session-helper";

export const USER__START_LOADING = "USER__START_LOADING";
export const USER__STOP_LOADING = "USER__STOP_LOADING";
export const USER__SET_COURSES = "USER__SET_COURSES";
export const USER__SET_LIST = "USER__SET_LIST";
export const USER__REMOVE_USER_FROM_GRID = "USER__REMOVE_USER_FROM_GRID";
export const USER__ACTIVATE = "USER__ACTIVATE";
export const USER__DEACTIVATE = "USER__DEACTIVATE";

export const startLoading = () => ({
  type: USER__START_LOADING,
});

export const stopLoading = () => ({
  type: USER__STOP_LOADING,
});

export function registerUser(form) {
  return function (dispatch) {
    service
      .registerUser(form)
      .then(() => {
        message.success("Confirme sua conta no email.");
        history.push("/login");
      })
      .catch((error) => {
        message.error(error.response.data.error);
      });
  };
}
export function updateUser(form) {
  return function (dispatch) {
    service
      .updateUser(form)
      .then(() => {
        const session = getSession().userData;
        message.success("Conta atualizada com sucesso");
        dispatch(
          setSession({
            ...session,
            user: {
              ...session.user,
              name: form.name,
              birthdate: form.birthdate,
              gender: form.gender,
            },
          })
        );
      })
      .catch((error) => {
        console.log(error);
        message.error(error.response.data.error);
      });
  };
}

export function setList(data) {
  return {
    type: USER__SET_LIST,
    payload: data,
  };
}

export function getUsers() {
  return function (dispatch) {
    dispatch(startLoading());
    return service
      .getUsers()
      .then((resp) => {
        dispatch(setList(resp.data));
        dispatch(stopLoading());
      })
      .catch(() => {
        dispatch(stopLoading());
        message.error("Ocorreu um erro");
      });
  };
}

export const removeUserFromGrid = (userId) => ({
  type: USER__REMOVE_USER_FROM_GRID,
  payload: userId,
});

export const deleteUser = (userId) => (dispatch) => {
  dispatch(startLoading());
  return service
    .deleteUser(userId)
    .then(() => {
      dispatch(getUsers());
      message.success("Usuário removido com sucesso");
    })
    .catch(() => message.error("Você não tem permissão para esta ação"))
    .finally(() => {
      dispatch(stopLoading());
    });
};

export const activateUser = (userId) => (dispatch) => {
  dispatch(startLoading());
  return service
    .activateUser(userId)
    .then(() => {
      // dispatch(removeUserFromGrid(userId));
      message.success("Usuário ativado com sucesso");
    })
    .finally(() => {
      dispatch(stopLoading());
    });
};

export const deactivateUser = (userId) => (dispatch) => {
  dispatch(startLoading());
  return service
    .deactivateUser(userId)
    .then(() => {
      // dispatch(removeUserFromGrid(userId));
      message.success("Usuário desativado com sucesso");
    })
    .finally(() => {
      dispatch(stopLoading());
    });
};

export const setCourses = (payload) => ({
  type: USER__SET_COURSES,
  payload,
});

export function getUserCourses() {
  return function (dispatch) {
    dispatch(startLoading());
    return getCourses(null, true, true)
      .then((resp) => {
        dispatch(setCourses(resp.data));
        dispatch(stopLoading());
      })
      .catch(() => message.error("Ocorreu um erro"));
  };
}

export function getUserPlansAction() {
  return function (dispatch) {
    dispatch(startLoading());
    return service
      .getUserPlans()
      .then((resp) => {
        dispatch(setCourses(resp.data));
        dispatch(stopLoading());
      })
      .catch((err) => {
        console.log(err);
        message.error("Ocorreu um erro no get plans");
      });
  };
}
