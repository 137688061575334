import Immutable from 'seamless-immutable'

import * as actions from './Session.action'
import { setSession, getSession } from '../../utils/session-helper'

const session = getSession()

const initialState = () =>
  Immutable({
    logged: session.logged || false,
    userData: session.userData || {},
    loginLoad: false
  })

export default function sessionReducer (state = initialState(), action) {
  switch (action.type) {
    case actions.SESSION__START_LOGIN_LOAD:
      return state.set('loginLoad', true)
    case actions.SESSION__STOP_LOGIN_LOAD:
      return state.set('loginLoad', false)
    case actions.SESSION__SET_SESSION: {
      setSession({ userData: action.payload, logged: true })
      return state.set('logged', true).set('userData', action.payload)
    }
    case actions.SESSION__LOGOUT: {
      setSession({ userData: {}, logged: false })
      return state.set('logged', false).set('userData', {})
    }
    default:
      return state
  }
}
