import Immutable from 'seamless-immutable'

import * as actions from './Courses.action'

const initialState = () =>
  Immutable({
    loading: false,
    coursesList: [],
    courseModalData: {
      show: false,
      loading: false,
      form: {
        id: null,
        name: '',
        description: '',
        coursePackageId: ''
      },
      editingMode: false
    },
  })

export default function coursesReducer(state = initialState(), action) {
  switch (action.type) {
    case actions.COURSES__START_LOADING:
      return state.set('loading', true)
    case actions.COURSES__STOP_LOADING:
      return state.set('loading', false)
    case actions.COURSES__SET_LIST:
      return state.set('coursesList', action.payload)
    case actions.COURSES_ADD_COURSE_TO_LIST:
      let aux = [...state.coursesList]
      const courseExist = aux.findIndex((course) => course.id === action.payload.id)
      if (courseExist != -1) {
        aux[courseExist] = action.payload
      } else {
        aux.push(action.payload)
      }
      return state.set(
        ['coursesList'],
        aux
      )
    case actions.COURSES_REMOVE_COURSE_FROM_LIST:
      return state.setIn(
        ['coursesList'],
        state.coursesList.filter((cls) => cls.id !== action.payload)
      )
    case actions.COURSES_OPEN_COURSE_MODAL:
      return state.setIn(['courseModalData', 'show'], true)
    case actions.COURSES_CLOSE_COURSE_MODAL:
      return state.set('courseModalData', initialState().courseModalData)
    case actions.COURSES_START_COURSE_MODAL_LOADING:
      return state.setIn(['courseModalData', 'loading'], true)
    case actions.COURSES_STOP_COURSE_MODAL_LOADING:
      return state.setIn(['courseModalData', 'loading'], false)
    case actions.COURSES_CHANGE_COURSE_FORM:
      return state.setIn(['courseModalData', 'form'], action.payload)
    case actions.COURSES_OPEN_COURSE_MODAL_TO_EDIT: {
      const course = action.payload
      return state
        .setIn(['courseModalData', 'form'], {
          id: course.id,
          name: course.name,
          description: course.description
        })
        .setIn(['courseModalData', 'show'], true)
        .setIn(['courseModalData', 'editingMode'], true)
    }
    default:
      return state
  }
}
