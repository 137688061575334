import React from "react";
import { Button, Col, Input, message, Row } from "antd";
import styles from "./Footer.module.scss";
import { useState } from "react";
import SiteContainer from "../../components/SiteContainer/SiteContainer";
import { ReactComponent as WhatsappIcon } from "../../images/whatsapp.svg";
import { ReactComponent as PhoneIcon } from "../../images/phone.svg";
import { ReactComponent as EmailIcon } from "../../images/email.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook.svg";

export default function Footer() {
  const [msg, setMsg] = useState("");

  const onChangeContactForm = (value) => {
    setMsg(value);
  };

  const onSendContactForm = () => {
    if (!msg) {
      return message.warning("Preencha todos os campos obrigatórios");
    }

    return (window.location.href =
      "mailto:contato@wjschool.com.br?subject=Primeiro%20Contato&body=" + msg);
  };
  return (
    <div className={styles.Footer}>
      <div className="contactForm">
        <SiteContainer>
          <div className="sectionTitle">Fale conosco!</div>
          <Row>
            <Col span={8}>
              <div className="content">
                <span className="title"><strong>É possível aprender inglês sozinho e sem sair de casa!</strong></span>
                <p>
                  Basta ter acesso ao material completo da WJ School, que vai te entregar o passo a passo para aprender o novo idioma da melhor forma.
                </p>
              </div>
            </Col>
            <Col span={16}>
              <div className="form">
                <div className="item">
                  <span className="label">Mensagem</span>
                  <Input.TextArea
                    placeholder="*Escreva sua mensagem"
                    value={msg}
                    onChange={(el) => onChangeContactForm(el.target.value)}
                  />
                </div>
                <Button
                  type="primary"
                  className="sendBtn"
                  onClick={onSendContactForm}
                >
                  Enviar
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className="infos">
                <div className="item">
                  <PhoneIcon />
                  <span>
                    Ligue agora: <a href="tel:+5588999945219"><b>88 9 9994-5219</b></a>
                  </span>
                </div>
                <div className="item">
                  <EmailIcon />
                  <a href="mailto:wjschool21@gmail.com"><span>wjschool21@gmail.com</span></a>
                </div>
                <div className="item">
                  <WhatsappIcon />
                  <span>
                    Fale conosco: <a href="https://wa.me/+5588999945219"><b>88 9 9994-5219</b></a>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </SiteContainer>
      </div>

      <div className="newsletter">
        <SiteContainer>
          <div className="networks">
            <ul>
              <li>
                <a href="https://facebook.com">
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a href="https://instagram.com">
                  <InstagramIcon />
                </a>
              </li>
            </ul>
          </div>

          <div className="copyright">
            <span>2020 © Copyright WJ School</span>
          </div>
        </SiteContainer>
      </div>
    </div>
  );
}
