import React from "react";
import { Button } from "antd";

import styles from "./HomePlans.module.scss";
import SiteContainer from "../SiteContainer/SiteContainer";
import PlanImage from "../../images/plan.png";
import history from "../../utils/history";
import { useDispatch } from "react-redux";
import { setPlan } from "../../redux/PurchasePlan/PurchasePlan.action";

export default function HomePlans({ list }) {
  const dispatch = useDispatch();
  const handleSubscriptionButton = (plan) => {
    dispatch(setPlan(plan));
    history.push(`/purchasePlan/${plan.id}`);
  };
  return (
    <div className={styles.HomePLans}>
      <SiteContainer>
        <div className="list">
          {list.map((plan) => (
            <div className="plan" key={plan.id}>
              <img src={PlanImage} alt="" className="planImage" />
              <span className="planName">{plan.name}</span>
              <span className="planPrice">
                {`R$ ${plan.fullPrice.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                })}`}
              </span>
              <div className="planDescription">{plan.description}</div>
              <div className="planFeatures">
                {plan.tags.map((tag, index) => (
                  <div className="tag" key={index}>
                    <span className="material-symbols-outlined">check</span>
                    <span>{tag}</span>
                  </div>
                ))}
              </div>
              <Button
                type="primary"
                onClick={() => handleSubscriptionButton(plan)}
              >
                MATRICULE-SE
              </Button>
            </div>
          ))}
        </div>
      </SiteContainer>
    </div>
  );
}
