import React, { useState } from "react";
import Img from "../../images/example3.png";
import styles from "./UserProgression.module.scss";
import history from "../../utils/history";
import { Col, message } from "antd";
import { useEffect } from "react";
import { getOneUserPlans } from "../../services/user.service";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import UserInfoAdmin from "../UserInfoAdmin/UserInfoAdmin";
function UserProgression({ location, userId }) {
  const [userPlans, setUserPlans] = useState([]);

  useEffect(() => {
    getOneUserPlans(userId)
      .then((response) => {
        setUserPlans(response.data);
      })
      .catch((err) => {
        message.error("Erro ao buscar planos do usuário.");
      });
  }, [userId]);
  return (
    <div className={styles.UserProgression}>
      <div className="info-header">
        <Breadcrumb location={location} />
        <UserInfoAdmin />
      </div>
      <div className="title">Pacotes</div>

      <Col className="list">
        {userPlans.length > 0 ? (
          userPlans.map((coursesPackage) => {
            const packages = coursesPackage.course_package;
            const courseshtml = packages.courses.map((course) => (
              <div
                className="course"
                key={course.id}
                onClick={() =>
                  history.push(`/control-panel/users/${userId}/course`, {
                    course: course,
                    userId,
                  })
                }
              >
                <img src={Img} alt="Logo" />
                <div>
                  <strong className="name">{course.name}</strong>
                  <div className="description">{course.description}</div>
                </div>
              </div>
            ));
            return (
              <Col style={{ marginBottom: 20 }}>
                <strong className="packageName">{packages.name}</strong>
                <span className="packageDescription">
                  {packages.description}
                </span>
                <div className="packageList">{courseshtml}</div>
              </Col>
            );
          })
        ) : (
          <strong className="noCourse">
            Não existe cursos para esse aluno.
          </strong>
        )}
      </Col>
    </div>
  );
}

export default UserProgression;
