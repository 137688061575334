import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from "./Header";
import Footer from "./Footer";
import DashboardCourses from "../components/DashboardCourses/DashboardCourses";
import { getUserPlansAction } from "../redux/User/User.actions";

export default function Dashboard() {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.user.courses);

  useEffect(() => {
    dispatch(getUserPlansAction());
  }, [dispatch]);

  return (
    <>
      <Header />
      <DashboardCourses coursesPackages={courses} />
      <Footer />
    </>
  );
}
