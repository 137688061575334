import React from 'react'

import styles from './HomeAbout.module.scss'
import SiteContainer from '../SiteContainer/SiteContainer'
import imgBanner from '../../images/banner2.png'

export default function HomeAbout (props) {
  return (
    <div className={styles.HomeAbout}>
      <SiteContainer>
        <h2 className="title">Sabe quando você pode começar? <b>Agora!</b></h2>
        <div className="text">
          <p>Aprender inglês em cursos presenciais que utilizam metodologias tradicionais pode levar muitos anos e tomar boa parte do seu tempo. Bom mesmo é ter acesso a uma metodologia descomplicada, com aulas que você pode acessar quando quiser e sem sair de casa.</p>
          <p>
          Dominar um novo idioma é essencial para quem deseja ter novas oportunidades no mercado de trabalho, ser promovido, viajar para novos países, viver novas experiências e conhecer novas culturas.
          </p>
          <p>
          Uma pesquisa do site de empregos Catho revelou que a diferença salarial entre um profissional com e sem domínio do inglês pode chegar a 70%. Quando o assunto é o espanhol, a diferença chega a 40%.
          <br />
          <i>Fonte: Correio Braziliense</i>
          </p>
        </div>
        <img src={imgBanner} alt="" className="banner" />
      </SiteContainer>
    </div>
  )
}
