import Immutable from 'seamless-immutable'

import * as actions from './Plans.action'

const initialState = () =>
  Immutable({
    loading: false,
    list: [],
    modal: {
      form: {
        name: '',
        description: '',
        tags: '',
        totalPrice: 0,
        maxPayTimes: 1
      },
      loading: false,
      show: false,
      editMode: false
    },
    planDetails: undefined
  })

export default function plansReducer(state = initialState(), action) {
  switch (action.type) {
    case actions.PLANS__START_LOADING:
      return state.set('loading', true)
    case actions.PLANS__STOP_LOADING:
      return state.set('loading', false)
    case actions.PLANS__SET_LIST:
      return state.set('list', action.payload)
    case actions.PLANS__OPEN_MODAL: {
      const { editMode, data } = action.payload
      return state
        .setIn(['modal', 'show'], true)
        .setIn(['modal', 'editMode'], editMode)
        .setIn(
          ['modal', 'form'],
          editMode
            ? {
              id: data.id,
              name: data.name,
              description: data.description,
              tags: (data.tags || []).join(','),
              totalPrice: data.fullPrice,
              maxPayTimes: data.maxPayTimes
            }
            : state.modal.form
        )
    }
    case actions.PLANS__CLOSE_MODAL:
      return state.set('modal', initialState().modal)
    case actions.PLANS__CHANGE_FORM:
      return state.setIn(['modal', 'form'], action.payload)
    case actions.PLANS__START_MODAL_LOADING:
      return state.setIn(['modal', 'loading'], true)
    case actions.PLANS__STOP_MODAL_LOADING:
      return state.setIn(['modal', 'loading'], false)
    case actions.PLANS__ADD_NEW_PLAN:
      return state.set('list', [...state.list, action.payload])
    case actions.PLANS__REMOVE_PLAN_FROM_GRID:
      return state.set(
        'list',
        state.list.filter((el) => el.id !== action.payload)
      )
    case actions.PLANS__UPDATE_PLAN:
      return state.set(
        'list',
        state.list.map((plan) => {
          if (plan.id !== action.payload.id) return plan
          return action.payload
        })
      )
    case actions.PLANS__SET_PLAN_DETAILS:
      return state.set('planDetails', action.payload)
    default:
      return state
  }
}
