import { message } from "antd";

import * as service from "../../services/session.service";
import history from "../../utils/history";

export const SESSION__SET_SESSION = "SESSION__SET_SESSION";
export const SESSION__START_LOGIN_LOAD = "SESSION__START_LOGIN_LOAD";
export const SESSION__STOP_LOGIN_LOAD = "SESSION__STOP_LOGIN_LOAD";
export const SESSION__LOGOUT = "SESSION__LOGOUT";

export function setSession(userData) {
  return {
    type: SESSION__SET_SESSION,
    payload: userData,
  };
}

export function startLoginLoad() {
  return { type: SESSION__START_LOGIN_LOAD };
}

export function stopLoginLoad() {
  return { type: SESSION__STOP_LOGIN_LOAD };
}

export function login(email, password) {
  return function (dispatch) {
    dispatch(startLoginLoad());
    return service
      .login(email, password)
      .then((resp) => {
        dispatch(setSession(resp.data));

        if (resp.data.user.isAdmin) {
          history.push("/control-panel/users");
        } else {
          history.push("/dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.response.data.error);
      })
      .finally(() => {
        dispatch(stopLoginLoad());
      });
  };
}

export function logout() {
  history.push("/");
  return { type: SESSION__LOGOUT };
}
