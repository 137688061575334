import Immutable from "seamless-immutable";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage-helper";

import * as actions from "./UserDetailsControlPanel.actions";

const initialState = () =>
  Immutable({
    name: getLocalStorageItem("wjschollUserDetailName"),
    email: getLocalStorageItem("wjschollUserDetailMail"),
  });

export default function UserDetailsControlPanel(
  state = initialState(),
  action
) {
  switch (action.type) {
    case actions.USER_DETAIL_NAME:
      setLocalStorageItem(action.payload, "wjschollUserDetailName");
      return state.set("name", action.payload);
    case actions.USER_DETAIL_EMAIL:
      setLocalStorageItem(action.payload, "wjschollUserDetailMail");
      return state.set("email", action.payload);
    default:
      return state;
  }
}
